import classnames from "classnames";

const Button = ({ children, className, type, icon = false, variant = "default", color = "primary", size = "md", hover = "on", disabled, onClick, ...props }) => {
    if (icon) {
        if (size === "xs") size = "w-6 h-6 !rounded-full f-center";
        if (size === "sm") size = "w-9 h-8 !rounded-full f-center";
        if (size === "md") size = "w-10 h-10 !rounded-full f-center";
        if (size === "lg") size = "w-14 h-14 !rounded-full f-center";
    } else {
        size = size === "xs" ? "px-2 py-1" : size === "sm" ? "px-3 py-1.5 text-sm" : size === "lgs" ? "px-12 py-3" : size === "lg" ? "px-16 py-3" : "px-6 py-2";
    }

    const colorVariants = {
        default: {
            primary: `text-primary ${hover === "on" && "hover:bg-primary-extralight"}`,
            white: `text-white ${hover === "on" && "hover:bg-gray-100"}`,
            black: `text-black ${hover === "on" && "hover:bg-gray-100"}`,
            gray: `text-gray ${hover === "on" && "hover:bg-gray-100"}`,
            grayLight: `text-gray-100 ${hover === "on" && "hover:bg-gray-100"}`,
            green: `text-green-600 ${hover === "on" && "hover:bg-green-200"}`,
        },
        contained: {
            primary: `bg-primary text-white ${hover === "on" && "hover:bg-primary-dark"}`,
            white: `bg-white text-primary ${hover === "on" && "hover:bg-primary hover:text-white"}`,
            black: `bg-black text-white`,
            gray: `bg-gray text-white ${hover === "on" && "hover:bg-gray-500"}`,
            grayLight: `bg-gray-100 text-gray ${hover === "on" && "hover:bg-gray-200"}`,
            green: `bg-green-600 text-white ${hover === "on" && "hover:bg-green-700 hover:text-white"}`,
        },
        outlined: {
            primary: `border border-primary text-primary ${hover === "on" && "hover:bg-primary hover:text-white"}`,
            white: `border border-white text-white ${hover === "on" && "hover:bg-white hover:text-primary"}`,
            black: `border border-black text-black ${hover === "on" && "hover:bg-gray-200 hover:text-black"}`,
            gray: `border border-gray text-gray ${hover === "on" && "hover:bg-gray-200"}`,
            grayLight: `border border-gray-100 text-gray ${hover === "on" && "hover:bg-gray-200"}`,
            green: `border border-green-600 text-green-600 ${hover === "on" && "hover:bg-green-700 hover:text-white"}`,
        },
    };

    if (!colorVariants[variant] === undefined) throw new Error(`No variant found for: [${variant}]`);
    if (!colorVariants[variant][color] === undefined) throw new Error(`No color found for variant: [${variant}, ${color}]`);

    return (
        <button type={type || "button"} className={classnames(`font-semibold transition-all duration-300 rounded-full ${size} ${colorVariants[variant][color]}`, className)} disabled={disabled} onClick={onClick} {...props}>
            {children}
        </button>
    );
};

export default Button;
