// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dot-custom {
    bottom: -24px;
    width: 100%;
    list-style: none;
    text-align: center;
    position: absolute;
    right: 15px;
    padding: 0;
}

.dot-custom li {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    padding: 0;
    cursor: pointer;
}

.dot-custom li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: 0;
    background: 0 0;
}

.dot-custom li button:before {
    font-size: 2.7rem;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: "•";
    text-align: center;
    opacity: 0.75;
    color: #d9d9d9;
}

.dot-custom li.slick-active button:before {
    opacity: 0.75;
    color: #ff6738;
}
`, "",{"version":3,"sources":["webpack://./src/components/Banner/slick.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;IAClB,WAAW;IACX,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,WAAW;IACX,YAAY;IACZ,UAAU;IACV,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,cAAc;IACd,cAAc;IACd,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,cAAc;AAClB","sourcesContent":[".dot-custom {\n    bottom: -24px;\n    width: 100%;\n    list-style: none;\n    text-align: center;\n    position: absolute;\n    right: 15px;\n    padding: 0;\n}\n\n.dot-custom li {\n    position: relative;\n    display: inline-block;\n    width: 20px;\n    height: 20px;\n    padding: 0;\n    cursor: pointer;\n}\n\n.dot-custom li button {\n    font-size: 0;\n    line-height: 0;\n    display: block;\n    width: 20px;\n    height: 20px;\n    padding: 5px;\n    cursor: pointer;\n    color: transparent;\n    border: 0;\n    outline: 0;\n    background: 0 0;\n}\n\n.dot-custom li button:before {\n    font-size: 2.7rem;\n    line-height: 20px;\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 20px;\n    height: 20px;\n    content: \"•\";\n    text-align: center;\n    opacity: 0.75;\n    color: #d9d9d9;\n}\n\n.dot-custom li.slick-active button:before {\n    opacity: 0.75;\n    color: #ff6738;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
