import * as React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { PageLoading } from "./components";
import { AuthProvider } from "./contexts/authContext";
import {
    AdminOutlet,
    Auth,
    DashboardAdmin,
    Home,
    Login,
    Register,
    MemberInfo,
    AuthorList,
    AuthorInformation,
    BannerManagement,
    NoticeManagement,
    PopupManagement,
    ProductManagement,
    SettlementMenegement,
    UpdateProduct,
    SettlementDetails,
    MemberList,
} from "./pages";

const Page404 = React.lazy(() => import("./pages/Page404/Page404"));

function App() {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <Home />,
        },
        {
            path: "/admin",
            element: <AdminOutlet />,
            children: [
                // Root
                {
                    path: "",
                    element: <DashboardAdmin />,
                    index: true,
                },
                // Overall
                {
                    path: "dashboard",
                    element: <DashboardAdmin />,
                },
                // Product
                {
                    path: "product/list",
                    element: <ProductManagement />,
                },
                {
                    path: "product/list/:id",
                    element: <UpdateProduct />,
                },
                // Author
                {
                    path: "author/list",
                    element: <AuthorList />,
                },
                {
                    path: "author/list/:id",
                    element: <AuthorInformation />,
                },
                // Member
                {
                    path: "members/list",
                    element: <MemberList />,
                },
                {
                    path: "members/list/:id",
                    element: <MemberInfo />,
                },
                // {
                //     path: "members/suspension",
                //     element: <MemberSuspension />,
                // },
                // Settlemrnt
                {
                    path: "settlement/list",
                    element: <SettlementMenegement />,
                },
                {
                    path: "settlement/list/:id",
                    element: <SettlementDetails />,
                },
                // System
                {
                    path: "setting/banner",
                    element: <BannerManagement />,
                },
                {
                    path: "setting/notice",
                    element: <NoticeManagement />,
                },
                {
                    path: "setting/popup",
                    element: <PopupManagement />,
                },
            ],
        },
        {
            path: "/auth",
            element: <Auth />,
            children: [
                {
                    path: "",
                    element: <Login />,
                    index: true,
                },
                {
                    path: "login",
                    element: <Login />,
                },
                {
                    path: "register",
                    element: <Register />,
                },
            ],
        },
        {
            path: "*",
            element: (
                <React.Suspense fallback={<PageLoading />}>
                    <Page404 />
                </React.Suspense>
            ),
        },
    ]);

    return (
        <AuthProvider>
            <RouterProvider router={router} />
        </AuthProvider>
    );
}

export default App;
