import React, { useState } from "react";
import formatDate from "../../utils/formatDate";
import Input from "../Input/Input";
import Button from "../Button/Button";
import { updateBanner } from "../../apis";
import { toast } from "react-hot-toast";

export default function Banner({ banner, type, handleDeleteBanner }) {
    const [imageFile, setImageFile] = useState(null);

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setImageFile(selectedFile);
        }
    };

    const [bannerValue, setBannerValue] = useState({
        name: banner?.name,
        url: banner?.strurl,
        image: banner?.imgsrc,
        start_at: banner?.start_at,
        end_at: banner?.end_at,
        rgb_code: banner?.rgb_code,
        order_index: banner?.order_index,
    });

    const handleChangeData = (e) => {
        let { name, value } = e.target;
        setBannerValue({ ...bannerValue, [name]: value });
    };

    const handleUpdateBanner = async () => {
        try {
            const formData = new FormData();
            formData.append("aid", banner?.id);
            formData.append("name", bannerValue?.name);
            formData.append("sAt", bannerValue?.start_at);
            formData.append("eAt", bannerValue?.end_at);
            formData.append("rgbCode", bannerValue?.rgb_code);
            formData.append("strurl", bannerValue?.url);
            formData.append("orderIndex", bannerValue?.order_index);
            if (imageFile) {
                formData.append("file", imageFile);
            }
            
            const data = await updateBanner({
                data: formData,
            });

            if (data.status > 299 || data.status < 200) {
                const message = data && data.message ? data.message : "Something went wrong";
                throw new Error(message);
            }

            toast.success("수정이 완료되었습니다");
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <React.Fragment key={banner.id}>
            <div className="grid grid-cols-12 gap-2 w-full mt-2 pb-2 mb-2 px-1 border-b border-b-gray-200 last:border-b-0 items-center">
                {type === "ad" ? (
                    <div>
                        <Input
                            type="text"
                            variant="outlined"
                            color="black"
                            name="order-index"
                            value={bannerValue.order_index}
                            onChange={handleChangeData}
                            className="text-center"
                        />
                    </div>
                ) : (
                    <Button
                        className="hover:bg-transparent underline underline-offset-2 "
                        onClick={() => handleDeleteBanner(banner.id)}
                    >
                        삭제
                    </Button>
                )}
                <div className=" col-span-2">
                    <Input
                        type="text"
                        variant="outlined"
                        color="black"
                        defaultValue={banner.name || "-"}
                        className="text-center"
                        name="name"
                        onChange={handleChangeData}
                    />
                </div>
                <div className=" col-span-3">
                    <Input
                        type="text"
                        variant="outlined"
                        color="black"
                        value={bannerValue.url || "-"}
                        className="text-center"
                        name="url"
                        onChange={handleChangeData}
                    />
                </div>
                <div className="">
                    <Input
                        type="text"
                        variant="outlined"
                        color="black"
                        defaultValue={`#${bannerValue.rgb_code}` || "-"}
                        className="text-center"
                        onChange={handleChangeData}
                    />
                </div>
                <div className="">
                    <Input
                        type="date"
                        variant="outlined"
                        value={formatDate(bannerValue.start_at) || "-"}
                        color="black"
                        name="start_at"
                        className="text-center"
                        onChange={handleChangeData}
                    />
                </div>
                <div className="">
                    <Input
                        type="date"
                        variant="outlined"
                        value={formatDate(bannerValue.end_at) || "-"}
                        color="black"
                        name="end_at"
                        className="text-center"
                        onChange={handleChangeData}
                    />
                </div>
                <div className="f-center gap-2 whitespace-nowrap col-span-2 ">
                    <label
                        htmlFor={`bannerImage-${banner.id}`}
                        className="bg-primary text-white hover:bg-primary-dark px-3 py-1.5 text-sm font-semibold transition-all duration-300 rounded-full"
                    >
                        파일선택
                        <Input
                            id={`bannerImage-${banner.id}`}
                            type="file"
                            className="hidden"
                            onChange={handleFileChange}
                        />
                    </label>

                    {imageFile && <p className="p-1 rounded-full">{imageFile?.name?.slice(0, 6)}...</p>}
                </div>
                <div className="">
                    <Button variant="contained" size="sm" onClick={handleUpdateBanner}>
                        저장
                    </Button>
                </div>
            </div>
            <div className="f-center w-full border-b border-b-gray-200 last:border-b-0 pb-2">
                <div className=" bg-gray-200 mx-auto rounded-3xl overflow-hidden flex items-center">
                    <img src={(imageFile && URL.createObjectURL(imageFile)) || bannerValue?.image} alt="" />
                </div>
            </div>
        </React.Fragment>
    );
}
