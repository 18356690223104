import _ from "lodash";
import moment from "moment";

const formatDate = (date, format = "yyyy-MM-dd", separator = "-") => {
    if (!date) return "";
    let d = moment(date, "YYYY-MM-DD HH:mm");
    if (_.isNumber(date)) {
        d = moment.utc(date);
    }

    const formattedDate = d.format("YYYY-MM-DD");

    return formattedDate;
};

export default formatDate;
