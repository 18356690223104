import { useState } from "react";
import { MdClose } from "react-icons/md";
import { Link } from "react-router-dom";
import { Button, FlagPicker, Input } from "../../../components";

const steps = {
    mobile: "mobile",
    otp: "otp",
    completed: "completed",
};

const MobileVerificationModal = ({ onClose }) => {
    const [step, setStep] = useState(steps.mobile);

    const handleClickClose = (e) => {
        if (onClose) onClose(e);
    };

    return (
        <div className="mobile_number_verification_modal slide-top relative max-w-sm bg-white w-full p-8 pt-5 rounded-xl">
            {step !== steps.completed && (
                <div className="absolute top-2 right-2 cursor-pointer">
                    <MdClose className="text-xl" onClick={handleClickClose} />
                </div>
            )}

            {step !== steps.completed && (
                <div className="heading mb-4">
                    <h2 className="text-center text-lg font-semibold">휴대폰 인증하기</h2>
                </div>
            )}

            {(step === steps.mobile || step === steps.otp) && (
                <>
                    <div className="inputs">
                        <div className="flex mb-3 gap-3">
                            <FlagPicker />
                            <Input variant="outlined" color="gray" placeholder="숫자만 입력해주세요" />
                        </div>

                        {step === steps.otp && <Input variant="outlined" color="gray" placeholder="인증번호를 입력해주세요" />}
                    </div>

                    <div className="action_button mt-8 w-full">
                        {step === steps.mobile && (
                            <Button variant="contained" color="primary" className="w-full" size="lg" onClick={(e) => setStep(steps.otp)}>
                                인증번호 보내기
                            </Button>
                        )}

                        {step === steps.otp && (
                            <Button variant="contained" color="primary" className="w-full" size="lg" onClick={(e) => setStep(steps.completed)}>
                                등록완료!
                            </Button>
                        )}
                    </div>
                </>
            )}

            {step === steps.completed && (
                <>
                    <div className="text-center mb-6">
                        <p className="mb-1">자꾸다꾸 작가로</p>
                        <p className="text-2xl font-bold">등록되었어요🎉</p>
                    </div>
                    <Link to="/auth/login">
                        <Button variant="contained" color="primary" className="w-full" size="lg">
                            작가 로그인
                        </Button>
                    </Link>
                </>
            )}
        </div>
    );
};

export default MobileVerificationModal;
