import { Link, useLocation, useParams } from "react-router-dom";
import { Button, FlagPicker, Input } from "../../components";
import excelIcon from "../../static/img/excel.png";
import { useEffect, useState } from "react";
import { blockUser, getUser, updateUser } from "../../apis";
import formatDate from "../../utils/formatDate";
import { formatCate, formatDiscount, formatPricewithComma, formatSubcate } from "../../utils/formatData";

const MemberInfo = () => {
    const { id } = useParams();
    const location = useLocation();
    const uuid = location.state.uuid;
    const [userInfo, setUserInfo] = useState({});
    const [products, setProducts] = useState([]);

    const [page, setPage] = useState({
        currentPage: 1,
        pageSize: 15,
        totalCount: 0,
        loading: false,
    });

    const fetchUserInfo = async (params, headers) => {
        setPage({ ...page, loading: true });
        try {
            const { data } = await getUser(params, headers);
            const { npage, nperPage, totalCount } = data;

            if (data.status > 299 || data.status < 200) {
                const message = data && data.message ? data.message : "Something went wrong";
                throw new Error(message);
            }

            setUserInfo(data.profile);
            setProducts(data.items);

            setPage({
                ...page,
                currentPage: Number(npage),
                pageSize: Number(nperPage),
                totalCount: Number(totalCount),
                loading: false,
            });
        } catch (error) {
            setPage({ ...page, loading: false });
            console.log(error);
        }
    };

    useEffect(() => {
        fetchUserInfo({ id: id });
    }, []);

    return (
        <div className="profile_page">
            <div className="heading">
                <h2 className="text-xl font-medium">회원관리</h2>
            </div>

            <hr className="border-gray-200 my-6" />

            <section className="details_container">
                <div className="details mb-20">
                    <div className="flex items-center border-t border-b border-gray-300">
                        <div className="w-1/2 flex items-center">
                            <div className="w-36 text-center text-white font-medium f-center p-3 bg-primary mr-5">
                                가입일
                            </div>

                            <p className="flex items-center gap-8 text-lg">{formatDate(userInfo.createdAt) || "-"}</p>
                        </div>
                        <div className="w-1/2 flex items-center">
                            <div className="w-36 text-center text-white font-medium f-center p-3 bg-primary mr-5">
                                기기고유번호
                            </div>

                            <p className="flex items-center gap-8 text-lg">{uuid || "-"}</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="sales_records mt-20">
                <div className="flex items-center mb-3">
                    <h5 className="text-gray">상품개수 : {products?.length}건</h5>
                    <div className="ml-auto">
                        <Button variant="outlined" color="green" size="sm" className="!rounded-full f-center">
                            <img
                                src={excelIcon}
                                alt="excel"
                                height="19px"
                                width="19px"
                                className="object-contain mr-2"
                            />
                            엑셀로 내려받기
                        </Button>
                    </div>
                </div>

                <div className="relative overflow-x-auto">
                    <table className="w-full text-sm text-left text-black border border-gray-300 whitespace-nowrap">
                        <thead className="text-base bg-gray-100 border-b border-b-gray-300">
                            <tr>
                                <th
                                    scope="col"
                                    className="px-6 py-4 text-center font-medium border-r border-r-gray-300"
                                >
                                    구입일
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-4 text-center font-medium border-r border-r-gray-300"
                                >
                                    대분류
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-4 text-center font-medium border-r border-r-gray-300"
                                >
                                    소분류
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-4 text-center font-medium border-r border-r-gray-300"
                                >
                                    상품명
                                </th>

                                <th
                                    scope="col"
                                    className="px-6 py-4 text-center font-medium border-r border-r-gray-300"
                                >
                                    판매가
                                </th>
                            </tr>
                        </thead>
                        <tbody className="font-normal">
                            {products.map((product, i) => (
                                <tr key={i} className="bg-white border-b border-gray-300 last:border-b-0">
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300 whitespace-nowrap">
                                        {formatDate(product.created_at) || "-"}
                                    </td>
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300">
                                        {formatCate(product.cate) || "-"}
                                    </td>
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300">
                                        {formatSubcate(product.subcate) || "-"}
                                    </td>
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300 text-primary underline underline-offset-2">
                                        <Link
                                            to={`/admin/product/list/${product.products_id}`}
                                            state={{ brandname: product.brand_name }}
                                        >
                                            {product.product_name || "-"}
                                        </Link>
                                    </td>

                                    <td className="px-6 py-3 text-center border-r border-r-gray-300">
                                        {formatPricewithComma(product.sell_price) || "-"}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
    );
};

export default MemberInfo;
