import { useParams, useSearchParams } from "react-router-dom";
import { Button, Pagination } from "../../components";
import excelIcon from "../../static/img/excel.png";
import { getBusinessFile, getSettlementDetail, settlementRegister } from "../../apis";
import { useEffect, useState } from "react";
import formatDate from "../../utils/formatDate";
import { formatBusinessType, formatCompanyNum, formatPricewithComma } from "../../utils/formatData";
import config from "../../config/config";
import { toast } from "react-hot-toast";

const SettlementDetails = () => {
    const [searchParams] = useSearchParams();
    const { id } = useParams();
    const bsUserId = searchParams.get("bs_users_id");
    const [settlement, setSettlement] = useState({});
    const [withval, setWithval] = useState({}); // New state to store withval section
    const [resultData, setResultData] = useState([]);
    const bankInfo = `${settlement?.bank_name} ${settlement?.bank_num}`;
    const [excelDownload, setExcelDownload] = useState(false);

    const [page, setPage] = useState({
        currentPage: 1,
        pageSize: 300,
        totalCount: 0,
        loading: false,
    });

    const fetchSettlement = async (params, headers) => {
        setPage({ ...page, loading: true });
        try {
            const { data } = await getSettlementDetail(params, headers);
            const { page, total } = data;

            if (data.status > 299 || data.status < 200) {
                const message = data && data.message ? data.message : "Something went wrong";
                throw new Error(message);
            }
            setSettlement(data);

            setPage({ ...page, currentPage: page, pageSize: 300, totalCount: total, loading: false });
        } catch (error) {
            setPage({ ...page, loading: false });
            console.error(error); // Log error for debugging
            toast.error("An error occurred while fetching settlement details."); // Provide user feedback
        }
    };

    const handleClickDownloadExcelFile = (e) => {
        setExcelDownload(true);
        try {
            window.open(
                `${config.apiUrl}/admins/reqs/excel?page=${page.currentPage}&perpage=${page.pageSize}&dpid=${settlement?.dp?.id}`,
                "_blank"
            );

            setExcelDownload(false);
        } catch (err) {
            setExcelDownload(false);
            console.error(err); // Log error for debugging
            toast.error("An error occurred while downloading the Excel file."); // Provide user feedback
        }
    };

    const handleDownloadFile = async (type) => {
        try {
            const { data } = await getBusinessFile({
                uid: settlement?.dp?.bs_users_id,
                ft: type,
            });

            if (data.status > 299 || data.status < 200) {
                const message = data && data.message ? data.message : "Something went wrong";
                throw new Error(message);
            }

            const blob = new Blob([data], { type: "application/pdf" });
            const url = URL.createObjectURL(blob);
            window.open(url);
        } catch (err) {
            console.error(err); // Log error for debugging
            toast.error("An error occurred while downloading the file."); // Provide user feedback
        }
    };

    const handlePageChange = (value) => {
        if (value === page.currentPage) return;
        setPage({ ...page, currentPage: value });
    };

    useEffect(() => {
        fetchSettlement({ rid: id, ruid: bsUserId, page: page.currentPage, perPage: page.pageSize });
    }, [page.currentPage]);

    useEffect(() => {
        if (settlement.withval) {
            setWithval(settlement.withval);
        }
    }, [settlement.withval]);

    const handleSettlementRegister = async (id) => {
        try {
            settlementRegister(id);
            toast.success("정산이 완료되었습니다.");
            fetchSettlement({
                page: 1,
                perPage: 300,
            });
        } catch (err) {
            toast.error("잠시 후 다시 시도해주세요.");
            fetchSettlement({
                page: 1,
                perPage: 300,
            });
        }
    };

    const groupByDate = (items) => {
        return items.reduce((acc, item) => {
            // 날짜를 기준으로 그룹화합니다.
            const date = item.withdraw_req_at.split(" ")[0]; // 'YYYY-MM-DD' 형식으로 날짜를 가져옵니다.
            if (!acc[date]) {
                acc[date] = {
                    date,
                    paid_price: 0,
                    comm: 0,
                    vat: 0,
                    designer_earn: 0,
                    mvat: 0,
                    withdraw_at: item.withdraw_at, // 현재 아이템의 withdraw_at을 사용합니다.
                };
            }
            // 해당 날짜에 대한 합계를 계산합니다.
            acc[date].paid_price += parseFloat(item.paid_price);
            acc[date].comm += parseFloat(item.comm);
            acc[date].vat += parseFloat(item.vat);
            acc[date].designer_earn += parseFloat(item.designer_earn);
            acc[date].mvat += parseFloat(item.mvat);

            return acc;
        }, {});
    };

    useEffect(() => {
        if (settlement.items && settlement.items.length > 0) {
            const groupedData = groupByDate(settlement.items);
            const newResultData = Object.values(groupedData);
            setResultData(newResultData); // resultData 상태 업데이트
        }
    }, [settlement.items]);

    return (
        <div className="settlement_page">
            <div className="heading">
                <h2 className="text-xl font-medium">정산관리</h2>
            </div>

            <hr className="my-6 border-gray-200" />

            <section className="details_container">
                {searchParams.get("type") === "individual" ? (
                    <DetailsIndividual settlement={settlement} handleDownloadFile={handleDownloadFile} />
                ) : (
                    <DetailsBusiness settlement={settlement} handleDownloadFile={handleDownloadFile} />
                )}
            </section>

            <section className="mt-20 sales_records">
                <div className="flex items-center mb-3">
                    <h5 className="text-gray">상품개수 : {resultData.length}건</h5>
                    <div className="ml-auto">
                        <Button
                            variant="outlined"
                            color="green"
                            size="sm"
                            className="!rounded-full f-center"
                            disabled={excelDownload}
                            onClick={handleClickDownloadExcelFile}
                        >
                            {" "}
                            <img
                                src={excelIcon}
                                alt="excel"
                                height="19px"
                                width="19px"
                                className="object-contain mr-2"
                            />
                            엑셀로 내려받기
                        </Button>
                    </div>
                </div>

                <div className="relative overflow-x-auto">
                    <table className="w-full text-sm text-left text-black border border-gray-300 whitespace-nowrap">
                        <thead className="text-base bg-gray-100 border-b border-b-gray-300">
                            <tr>
                                <th
                                    scope="col"
                                    className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                                >
                                    작가명
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                                >
                                    정산완료일
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                                >
                                    정산신청일
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                                >
                                    판매합계금액
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                                >
                                    수수료
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                                >
                                    부가세 
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                                >
                                    원천세 
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                                >
                                    정산금액
                                </th>
                                <th scope="col" className="px-6 py-4 font-medium text-center">
                                    정산계좌
                                </th>
                            </tr>
                        </thead>
                        <tbody className="font-normal">
                            {/* {settlement?.items?.map((product, i) => (
                                <tr key={i} className="bg-white border-b border-gray-300 last:border-b-0">
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300 whitespace-nowrap">
                                        {settlement?.dp?.brand_name}
                                    </td>
                                    {product?.withdraw_at !== "" &&
                                    product?.withdraw_at !== undefined &&
                                    product?.withdraw_at !== null ? (
                                        <td className="px-6 py-3 text-center border-r border-r-gray-300">
                                            {formatDate(product?.withdraw_at)}
                                        </td>
                                    ) : (
                                        <td className="px-6 py-3 text-center border-r border-r-gray-300 text-primary">
                                            <button className="underline" onClick={() => handleSettlementRegister(id)}>
                                                정산완료
                                            </button>
                                        </td>
                                    )}
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300">
                                        {formatDate(product?.withdraw_req_at)}
                                    </td>
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300">
                                        {formatPricewithComma(product?.paid_price)}원
                                    </td>
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300">
                                        {formatPricewithComma(product.comm)}원
                                    </td>
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300">
                                        {formatPricewithComma(product.vat)}원
                                    </td>
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300 text-primary">
                                        {formatPricewithComma(product?.designer_earn)}원
                                    </td>
                                    <td className="px-6 py-3 text-center">{bankInfo}</td>
                                </tr>
                            ))} */}

                            {resultData.map((data, i) => (
                                <tr key={i} className="bg-white border-b border-gray-300 last:border-b-0">
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300 whitespace-nowrap">
                                        {settlement?.dp?.brand_name}
                                    </td>
                                    {data.withdraw_at !== "" &&
                                    data.withdraw_at !== undefined &&
                                    data.withdraw_at !== null ? (
                                        <td className="px-6 py-3 text-center border-r border-r-gray-300">
                                            {formatDate(data.withdraw_at)}
                                        </td>
                                    ) : (
                                        <td className="px-6 py-3 text-center border-r border-r-gray-300 text-primary">
                                            <button className="underline" onClick={() => handleSettlementRegister(id)}>
                                                정산완료
                                            </button>
                                        </td>
                                    )}
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300">
                                        {formatDate(data.date)}
                                    </td>
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300">
                                        {formatPricewithComma(withval.paid_price)}원
                                    </td>
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300">
                                        {formatPricewithComma(withval.comm)}원
                                    </td>
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300">
                                        {formatPricewithComma(withval.vat)}원
                                    </td>
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300">
                                        {formatPricewithComma(
                                            (Math.floor(
                                                ((parseFloat(withval.paid_price || 0) - parseFloat(withval.comm || 0) - parseFloat(withval.vat || 0)) * 0.033) / 10
                                            ) * 10)
                                        )}원
                                    </td>
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300 text-primary">
                                        {formatPricewithComma(withval.mvat)}원
                                    </td>
                                    <td className="px-6 py-3 text-center">{bankInfo}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </section>
            <section className="mt-5 pagination f-center">
                <Pagination
                    pageSize={page.pageSize}
                    onPageChange={handlePageChange}
                    totalCount={page.totalCount}
                    currentPage={page.currentPage}
                />
            </section>
        </div>
    );
};

export default SettlementDetails;

const DetailsIndividual = ({ settlement, handleDownloadFile }) => {
    return (
        <div className="mb-8 details">
            <div className="flex items-center border-t border-gray-300">
                <div className="flex items-center w-2/5">
                    <div className="p-3 mr-5 font-medium text-center text-white w-36 f-center bg-primary">
                        사업자 형태
                    </div>

                    <p className="flex items-center gap-8 text-lg">
                        {formatBusinessType(settlement?.dp?.business_type) || "-"}
                    </p>
                </div>

                <div className="flex items-center w-1/2">
                    <div className="p-3 mr-5 font-medium text-center text-white w-36 f-center bg-primary">구 분</div>

                    <div className="flex items-center justify-between">사업소득(3.3%)</div>
                </div>
            </div>

            <div className="flex items-center border-t border-gray-300">
                <div className="flex items-center w-2/5">
                    <div className="p-3 mr-5 font-medium text-center text-white w-36 f-center bg-primary">성 명</div>

                    <p className="flex items-center gap-8 text-lg">{settlement?.dp?.name || "-"}</p>
                </div>

                <div className="flex items-center w-1/2">
                    <div className="p-3 mr-5 font-medium text-center text-white w-36 f-center bg-primary">작가명</div>

                    <p className="flex items-center gap-8 text-lg">{settlement?.brand_name}</p>
                </div>
            </div>

            <div className="flex items-center border-t border-gray-300">
                <div className="flex items-center w-2/5">
                    <div className="p-3 mr-5 font-medium text-center text-white w-36 f-center bg-primary">
                        주민등록번호
                    </div>

                    <p className="flex items-center gap-8 text-lg">{settlement?.dp?.personal_num || "-"}</p>
                </div>

                <div className="flex items-center w-3/5">
                    <div className="flex-shrink-0 p-3 mr-5 font-medium text-center text-white w-36 f-center bg-primary">
                        계좌정보
                    </div>

                    <p className="flex items-center gap-8 text-lg">
                        {" "}
                        {`${settlement?.bank_name} ${settlement?.bank_num} ${settlement?.bank_holder}` || "-"}
                    </p>
                </div>
            </div>

            <div className="flex items-center border-t border-b border-gray-300">
                <div className="flex items-center w-1/2">
                    <div className="p-3 mr-5 font-medium text-center text-white w-36 f-center bg-primary">통장사본</div>

                    <button
                        className="flex items-center gap-8 text-lg underline text-primary"
                        onClick={() => handleDownloadFile("bank")}
                    >
                        {settlement?.dp?.bank_file_name || "-"}
                    </button>
                </div>
            </div>
        </div>
    );
};

const DetailsBusiness = ({ settlement, handleDownloadFile }) => {
    return (
        <div className="mb-8 details">
            <div className="flex items-center border-t border-gray-300">
                <div className="flex items-center w-1/2">
                    <div className="p-3 mr-5 font-medium text-center text-white w-36 f-center bg-primary">
                        사업자 형태
                    </div>

                    <p className="flex items-center gap-8 text-lg">
                        {settlement?.dp?.business_type === "1" ? "사업자(개인)" : "사업자(법인)"}
                    </p>
                </div>

                <div className="flex items-center w-1/2">
                    <div className="p-3 mr-5 font-medium text-center text-white w-36 f-center bg-primary">작가명</div>

                    <div className="flex items-center justify-between">{settlement?.brand_name || "-"}</div>
                </div>
            </div>

            <div className="flex items-center border-t border-gray-300">
                <div className="flex items-center w-1/2">
                    <div className="p-3 mr-5 font-medium text-center text-white w-36 f-center bg-primary">상호명</div>

                    <p className="flex items-center gap-8 text-lg">{settlement?.dp?.company_name || "-"}</p>
                </div>

                <div className="flex items-center w-1/2">
                    <div className="p-3 mr-5 font-medium text-center text-white w-36 f-center bg-primary">대표자명</div>

                    <p className="flex items-center gap-8 text-lg">{settlement?.dp?.name || "-"}</p>
                </div>
            </div>

            <div className="flex items-center border-t border-gray-300">
                <div className="flex items-center w-1/2">
                    <div className="p-3 mr-5 font-medium text-center text-white w-36 f-center bg-primary">
                        사업자등록번호
                    </div>

                    <p className="flex items-center gap-8 text-lg">
                        {(settlement?.dp && formatCompanyNum(settlement?.dp?.company_number)) || "-"}
                    </p>
                </div>

                <div className="flex items-center w-1/2">
                    <div className="p-3 mr-5 font-medium text-center text-white w-36 f-center bg-primary">
                        사업자등록증
                    </div>

                    <button
                        className="flex items-center gap-8 text-lg underline text-primary"
                        onClick={() => handleDownloadFile("business")}
                    >
                        {settlement?.dp?.cert_file_name || "-"}
                    </button>
                </div>
            </div>

            <div className="flex items-center border-t border-b border-gray-300">
                <div className="flex items-center w-1/2">
                    <div className="p-3 mr-5 font-medium text-center text-white w-36 f-center bg-primary">계좌정보</div>

                    <p className="flex items-center gap-8 text-lg">
                        {`${settlement?.bank_name} ${settlement?.bank_num} ${settlement?.bank_holder}` || "-"}
                    </p>
                </div>

                <div className="flex items-center w-1/2">
                    <div className="p-3 mr-5 font-medium text-center text-white w-36 f-center bg-primary">통장사본</div>

                    <button
                        className="flex items-center gap-8 text-lg underline text-primary"
                        onClick={() => handleDownloadFile("bank")}
                    >
                        {settlement?.dp?.bank_file_name || "-"}
                    </button>
                </div>
            </div>
        </div>
    );
};
