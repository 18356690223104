import React, { useEffect, useState, useCallback } from "react";
import { BsPlusCircleFill, BsPlusLg, BsTrash } from "react-icons/bs";
import { Button, Input, RadioButton, Select, TextArea } from "../../components";
import colorPickerIcon from "../../static/img/color_picker.png";
import { MdClose } from "react-icons/md";
import Tagify from "@yaireo/tagify/dist/react.tagify";
import {
    addProductFile,
    addProductThumb,
    createProduct,
    deleteFile,
    deleteThumb,
    productFilters,
    uploadForm,
    reviewProduct,
} from "../../apis";
import toast from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { formatProductState } from "../../utils/formatData";
import ProductThumbnail from "../../components/ProductThumbnail";
import { FiEdit } from "react-icons/fi";
import config from "../../config/config";

const AddProduct = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const location = useLocation();
    const brandname = location.state?.brandname;
    const [colors, setColors] = useState([]);
    const [pickColorList, setPickColorList] = useState([]);
    const [selectedFileIds, setSelectedFileIds] = useState([]);
    const [newFiles, setNewFiles] = useState([]);
    const [isFree, setIsFree] = useState(false);
    const [newMainThumb, setNewMainThumb] = useState(null);
    const [allSubThumbs, setAllSubThumbs] = useState([]);
    const [comment, setComment] = useState("");
    const [hashTag, setHashTag] = useState("");
    const [uploadFormData, setUploadFormData] = useState({
        price_list: [],
        files: [],
        fetched: false,
    });
    const subcate = {
        sticker: [
            { value: "0", name: "캐릭터 스티커" },
            { value: "1", name: "메모지" },
            { value: "2", name: "데코 스티커" },
            { value: "3", name: "마스킹 테이프" },
        ],
        diary: [
            { value: "10", name: "다이어리/플래너" },
            { value: "11", name: "노트" },
            { value: "12", name: "속지" },
        ],
    };

    const [productFiltersData, setProductFiltersData] = useState({
        0: "sticker",
        1: "diary",
        sticker: { fetched: false, type: [], mood: [] },
        diary: { fetched: false, type: [], mood: [] },
    });

    const [page, setPage] = useState({ loading: false, creating: false });
    const [product, setProduct] = useState({
        pid: "",
        psk_ids: [],
        colors: [],
        colorsId: [],
        cate: "0",
        subcate: "0",
        name: "",
        inst: "",
        priceid: "1",
        tags: "",
        fmoods: [],
        ftypes: [],
        created_at: "",
        mainthumb: "",
    });

    const settings = {
        maxTags: 10,
        editTags: false,
        originalInputValueFormat: (valuesArr) => valuesArr.map((item) => item.value),
    };

    useEffect(() => {
        // brandname이 없으면 /admin/product/list로 리다이렉트
        if (!brandname) {
            navigate("/admin/product/list");
        }
    }, [brandname, navigate]);

    const onHashTagChange = useCallback((e) => {
        // const tagArray = e.detail.value.split(",");
        // setHashTag(tagArray);
        // setProduct({ ...product, tags: tagArray });
        const tag = e.detail.value;
        setHashTag(tag);
        setProduct((prevData) => ({ ...prevData, tags: e.detail.value }));
    }, []);

    useEffect(() => {
        setProduct({ ...product, tags: hashTag });
    }, [hashTag]);

    const fetchUploadFormData = async (params, headers) => {
        setPage({ ...page, loading: true });
        try {
            if (uploadFormData.fetched) return;
            const { data } = await uploadForm({ pid: id });

            setUploadFormData({ ...data, fetched: true });

            setProduct({
                ...product,
                psk_ids: data?.product?.psk_ids?.split(",").map(Number) || [],
                colorsId: data?.product?.psc_ids?.split(",").map(Number) || [],
                name: data?.product?.name,
                priceid: data?.product?.price_tiers_id,
                pid: data?.product?.id,
                inst: data?.product?.inst,
                cate: data?.product?.cate,
                subcate: data?.product?.subcate,
                // tags: data?.product?.pst_names?.replace(/,/g, " "),
                tags: data?.product?.pst_names,
                created_at: data?.product?.created_at,
                fmoods: [],
                ftypes: [],
                mainthumb: data?.product?.mainthumb_src,
            });
            setColors(data.product_colors);

            setAllSubThumbs(
                data.addit_thumbs.map((item, i) => {
                    let imgsrc = item.imgsrc.split("&sm=t")[0]; // 또는 let imgsrc = item.imgsrc.replace(/&sm=t/, '');
                    return {
                        ...item,
                        imgsrc,
                        type: "old",
                        kind: "original",
                        index: i,
                    };
                })
                // data.addit_thumbs.map((item, i) => ({
                //     ...item,
                //     type: "old",
                //     kind: "original",
                //     index: i,
                // }))
            );
            if (data?.product?.price_tiers_id === "1") {
                setIsFree(true);
            }
        } catch (err) {
            console.log(err);
        }
        setPage({ ...page, loading: false });
    };

    const fetchProductFiltersData = async (params, headers) => {
        setPage({ ...page, loading: true });
        try {
            let type = "";

            if (params.cate.toString() === "0") {
                if (["0", "1", "2", "3"].includes(params.subcate.toString())) {
                    type = "sticker";
                }
            } else if (params.cate.toString() === "1") {
                if (["10", "11", "12"].includes(params.subcate.toString())) {
                    type = "diary";
                }
            }

            if (type === "sticker") {
                const { data } = await productFilters(params, headers);
                setProductFiltersData({
                    ...productFiltersData,
                    sticker: { fetched: true, ...data },
                });
            }

            if (type === "diary") {
                const { data } = await productFilters(params, headers);
                setProductFiltersData({
                    ...productFiltersData,
                    diary: { fetched: true, ...data },
                });
            }
        } catch (err) {
            console.log(err);
        }
        setPage({ ...page, loading: false });
    };

    useEffect(() => {
        fetchProductFiltersData({ cate: product.cate, subcate: product.subcate });
    }, [product.cate, product.subcate]);

    const saveTypesandMoods = () => {
        const ftypes = [];
        const fmoods = [];

        const currentFiltersData = productFiltersData[productFiltersData[product.cate]];

        if (currentFiltersData) {
            product.psk_ids.forEach((id) => {
                const foundType = currentFiltersData.type.find((type) => parseInt(type.id) === id);
                const foundMood = currentFiltersData.mood.find((mood) => parseInt(mood.id) === id);

                if (foundType) {
                    ftypes.push(id);
                } else if (foundMood) {
                    fmoods.push(id);
                }
            });

            setProduct((prevProduct) => ({ ...prevProduct, ftypes, fmoods }));
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            await fetchUploadFormData();
            if (productFiltersData[productFiltersData[product.cate]]?.fetched && product.psk_ids.length !== 0) {
                saveTypesandMoods();
            }
        };

        fetchData();
    }, [product.psk_ids, productFiltersData]);

    const saveColors = () => {
        const selectedColors = product.colorsId
            .map((colorId) => {
                const selectedColor = colors.find((color) => parseInt(color.id) === colorId);

                return selectedColor ? selectedColor.rgb_code : null;
            })
            .filter((rgb_code) => rgb_code !== null);

        setProduct({ ...product, colors: selectedColors });

        const unSelectedColors = colors.filter((color) => !product.colorsId.includes(parseInt(color.id)));
        setPickColorList(unSelectedColors);
    };

    useEffect(() => {
        if (product.colorsId.length !== 0) {
            saveColors();
        }
    }, [colors, product.colorsId]);

    const handleChangeData = (e, _name, _value) => {
        let { name, value } = e.target;

        if (_name) name = _name;
        if (_value) value = _value;

        if (name === "name" && value.length >= 100 && product.name.length >= 100) return;

        if (name === "inst" && value.length >= 1000 && product.inst.length >= 1000) return;

        if (name === "cate") {
            return setProduct({ ...product, cate: value, subcate: value === "0" ? "0" : "10" });
        }

        if (name === "subcate") {
            let cateValue = "0";
            if (value === "10" || value === "11" || value === "12") {
                cateValue = "1";
            }
            return setProduct({ ...product, cate: cateValue, subcate: value });
        }

        setProduct({ ...product, [name]: value });
    };

    const handleClickToggleFTypes = (e, ftype) => {
        const i = product.ftypes.indexOf(parseInt(ftype.id));
        if (i > -1) {
            product.ftypes.splice(i, 1);
        } else {
            product.ftypes.push(parseInt(ftype.id));
        }

        setProduct({ ...product });
    };

    const handleClickToggleFMoods = (e, fmood) => {
        const i = product.fmoods.indexOf(parseInt(fmood.id));

        if (i > -1) {
            product.fmoods.splice(i, 1);
        } else {
            product.fmoods.push(parseInt(fmood.id));
        }

        setProduct({ ...product });
    };

    const handleOpenEditMainthumb = () => {
        document.getElementById("mainThumbChange").click();
    };

    const handleChangeMainThumb = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setNewMainThumb(selectedFile);
        }
    };

    // 제거할 파일 아이디 추가
    const handleSelectedFileId = (file) => {
        setSelectedFileIds([...selectedFileIds, file.id]);

        setUploadFormData((prevFormData) => {
            const filteredFiles = prevFormData.files.filter((uploadedFile) => uploadedFile.id !== file.id);
            return { ...prevFormData, files: filteredFiles };
        });
    };

    // newfiles에 있는 아이템은 삭제시 selected에 추가 되지 않음
    const handleDeleteToNewFiles = (index) => {
        const filteredFiles = newFiles.filter((file, i) => i !== index);
        setNewFiles(filteredFiles);
    };

    // 새로운 파일 추가
    const handleAddFile = (e) => {
        const selectedFiles = e.target.files;
        const newFileList = [];

        for (let i = 0; i < selectedFiles.length; i++) {
            const file = selectedFiles[i];
            if (product.cate === "0") {
                if (file.type !== "image/png") {
                    toast.error("png 파일만 추가 가능합니다");
                    continue;
                }

                newFileList.push(file);
            } else if (product.cate === "1") {
                if (file.type !== "application/pdf") {
                    toast.error("pdf 파일만 추가 가능합니다");
                    continue;
                }

                newFileList.push(file);
            }
        }

        if (uploadFormData?.files.length + newFileList.length > 10) {
            toast.error("파일은 10개까지 추가 가능합니다");
            return;
        }

        if (newFileList.length > 0) {
            setNewFiles([...newFiles, ...newFileList]);
        }
    };

    // 메인 썸네일 업로드
    const addMainThumb = async () => {
        try {
            const formData = new FormData();
            formData.append("pid", product.pid);
            formData.append("file", newMainThumb);
            formData.append("thumbnum", "0");

            const data = await addProductThumb({ data: formData });

            if (data.status > 299 || data.status < 200) {
                const message = data && data.message ? data.message : "Something went wrong";
                throw new Error(message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleOpenEditSubthumb = (index) => {
        document.getElementById(`subThumbChange-${index}`).click();
    };

    const handleAddSubThumbs = (e, index, thumb) => {
        const selectedFile = e.target.files[0];

        if (selectedFile) {
            const updatedSubThumbs = [...allSubThumbs];
            updatedSubThumbs.push({
                file: selectedFile,
                index,
                type: "add",
                kind: "new",
            });

            updatedSubThumbs.forEach((thumbs) => {
                if (thumbs === thumb) {
                    thumbs.type = "delete";
                }
            });

            setAllSubThumbs(updatedSubThumbs);
        }
    };

    const handleAddNewSubThumbs = (e, type) => {
        const selectedFiles = Array.from(e.target.files);
        if (selectedFiles.length > 0) {
            const newSubThumbs = selectedFiles.map((selectedFile, index) => ({
                file: selectedFile,
                index: allSubThumbs.length + index,
                type: type,
                kind: "new",
            }));

            setAllSubThumbs([...allSubThumbs, ...newSubThumbs]);
        }
    };

    const handleDeleteSubThumbs = (e, i, thumb) => {
        const updatedThumbs = allSubThumbs.map((item) => {
            if (item === thumb) {
                return {
                    ...thumb,
                    type: "delete",
                };
            }
            return item;
        });
        setAllSubThumbs(updatedThumbs);
    };

    const removeSubThumbs = async () => {
        const filteredItem = allSubThumbs.filter((subThumb) => {
            return subThumb.type === "delete" && subThumb.kind === "original";
        });

        try {
            for (const item of filteredItem) {
                const data = await deleteThumb({
                    data: {
                        pid: product.pid,
                        imgid: item.id,
                    },
                });

                if (data.status > 299 || data.status < 200) {
                    const message = data && data.message ? data.message : "Something went wrong";
                    throw new Error(message);
                }
            }
        } catch (err) {
            toast.error(err.response.data.error_string);
            console.log(err);
        }
    };

    const addSubThumbs = async () => {
        const filteredItem = allSubThumbs.filter((subThumb) => {
            return subThumb.type === "add" && subThumb.kind === "new";
        });

        try {
            for (const item of filteredItem) {
                const formData = new FormData();
                formData.append("pid", product.pid);
                formData.append("file", item.file);
                formData.append("thumbnum", String(item.index + 1));

                const data = await addProductThumb({ data: formData });

                if (data.status > 299 || data.status < 200) {
                    const message = data && data.message ? data.message : "Something went wrong";
                    throw new Error(message);
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    const addFile = async () => {
        try {
            for (const file of newFiles) {
                const formData = new FormData();
                formData.append("pid", product.pid);
                formData.append("file", file);

                await new Promise((resolve) => setTimeout(resolve, 500));
                const data = await addProductFile({ data: formData });

                if (data.status > 299 || data.status < 200) {
                    const message = data && data.message ? data.message : "Something went wrong";
                    throw new Error(message);
                }
            }
        } catch (err) {
            console.log(err);
        }
    };

    // 파일 삭제
    const deletedFile = async () => {
        try {
            for (const id of selectedFileIds) {
                const data = await deleteFile({
                    data: {
                        fileid: id,
                        pid: product.pid,
                    },
                });

                if (data.status > 299 || data.status < 200) {
                    const message = data && data.message ? data.message : "Something went wrong";
                    throw new Error(message);
                }
            }
        } catch (err) {
            toast.error(err.response.data.error_string);
            console.log(err);
        }
    };

    const handleOpenFile = (id) => {
        window.open(`${config.apiUrl}/api/products/files/${id}`, "_blank");
    };

    const handleCreateOpenFile = (selectedImage) => {
        if (selectedImage) {
            const reader = new FileReader();
            reader.onload = () => {
                const imageWindow = window.open("", "_blank");
                const htmlContent = `<html><head><title>Image Preview</title></head><body><img src="${reader.result}" /></body></html>`;
                imageWindow.document.write(htmlContent);
            };
            reader.readAsDataURL(selectedImage);
        }
    };

    const handleOpenFileInNewWindow = (selectedFiles, index) => {
        const selectedImage = selectedFiles[index];
        if (selectedImage) {
            handleCreateOpenFile(selectedImage);
        }
    };

    const handleOpenFileInNewWindowAdd = (selectedFiles, index) => {
        if (selectedFiles) {
            handleCreateOpenFile(selectedFiles);
        }
    };

    const handleCreateOpenFilePdf = (selectedFile, fileType) => {
        if (selectedFile) {
            const reader = new FileReader();
            reader.onload = () => {
                const fileBlob = new Blob([reader.result], { type: fileType });
                const fileURL = URL.createObjectURL(fileBlob);
                window.open(fileURL, "_blank");
            };
            reader.readAsArrayBuffer(selectedFile);
        }
    };

    // const handleSaveProduct = async (e) => {
    //     const tid = toast.loading("상품 심사중...");
    //     setPage({ ...page, creating: true });
    //     try {
    //         const productData = {
    //             cate: product.cate,
    //             subcate: product.subcate,
    //             colors: product.colorsId.join(","),
    //             filterTypes: product.ftypes.join(","),
    //             filterMoods: product.fmoods.join(","),
    //             name: product.name,
    //             inst: product.inst,
    //             priceid: isFree === true ? "1" : product.priceid,
    //             tags: product.tags.replace(/\s+/g, ","),
    //         };

    //         const formData = new FormData();
    //         formData.append("productId", product.pid);
    //         formData.append("cate", productData.cate);
    //         formData.append("subcate", productData.subcate);
    //         formData.append("colors", productData.colors);
    //         formData.append("filterTypes", productData.filterTypes);
    //         formData.append("filterMoods", productData.filterMoods);
    //         formData.append("name", productData.name);
    //         formData.append("inst", productData.inst);
    //         formData.append("priceid", productData.priceid);
    //         formData.append("tags", productData.tags);
    //         formData.append("size", "hor");

    //         const { data } = await createProduct({ data: formData });

    //         if (data.status > 299 || data.status < 200) {
    //             const message = data && data.message ? data.message : "Something went wrong";
    //             throw new Error(message);
    //         }

    //         if (newFiles.length !== 0) {
    //             await addFile();
    //         }

    //         if (selectedFileIds.length !== 0) {
    //             await deletedFile();
    //         }

    //         if (newMainThumb) {
    //             await addMainThumb();
    //         }

    //         const filtereddeleteSubThumbs = allSubThumbs.filter((subThumb) => {
    //             return subThumb.type === "delete" && subThumb.kind === "original";
    //         });

    //         const filterednewSubThumbs = allSubThumbs.filter((subThumb) => {
    //             return subThumb.type === "add" && subThumb.kind === "new";
    //         });

    //         if (filtereddeleteSubThumbs && filtereddeleteSubThumbs.length !== 0) {
    //             await removeSubThumbs();
    //         }

    //         if (filterednewSubThumbs && filterednewSubThumbs.length !== 0) {
    //             await addSubThumbs();
    //         }

    //         await handleClickCompleteProduct();

    //         toast.success("심사가 완료되었습니다", { id: tid });
    //     } catch (err) {
    //         toast.error(err.response.data.error_string, { id: tid });
    //         console.log(err);
    //     }
    //     setPage({ ...page, creating: false });
    // };

    // useEffect(() => {
    //     console.log(product.pid, "pid----");
    //     console.log(id);
    // }, [product]);

    const handleClickCompleteProduct = async () => {
        try {
            const data = await reviewProduct({
                id: product.pid,
                data: {
                    content: comment,
                    status: "COMPLETE",
                },
            });

            if (data.status > 299 || data.status < 200) {
                const message = data && data.message ? data.message : "Something went wrong";
                throw new Error(message);
            }
            toast.success("심사가 완료되었습니다");
        } catch (err) {
            console.log(err);
        }
    };

    const handleClickRejectProduct = async () => {
        try {
            const data = await reviewProduct({
                id: product.pid,
                data: {
                    content: comment,
                    status: "REJECT",
                },
            });

            if (data.status > 299 || data.status < 200) {
                const message = data && data.message ? data.message : "Something went wrong";
                throw new Error(message);
            }

            toast.success("심사가 완료되었습니다");
        } catch (err) {
            console.log(err);
        }
    };

    const handleToggleColor = (e, color) => {
        const colorId = parseInt(color.id);
        if (product.colorsId.includes(colorId)) {
            setProduct({
                ...product,
                colorsId: product.colorsId.filter((id) => id !== colorId),
            });
        } else {
            setProduct({ ...product, colorsId: [...product.colorsId, colorId] });
        }
    };

    return (
        <div className="add_product_page">
            <section className="flex items-center justify-between page_header">
                <div className="flex items-center gap-3">
                    <h2 className="text-base">상품관리</h2>
                </div>
            </section>

            <hr className="my-6 border-gray-200" />

            <section className="form">
                <div className="mb-8 filters">
                    <div className="flex items-center border-t border-gray-300">
                        <div className="p-3 mr-5 font-medium text-center text-white w-36 f-center bg-primary">
                            상 <span className="block mx-3"></span> 태
                        </div>

                        <p className="text-primary">{formatProductState(uploadFormData?.product_status)}</p>
                    </div>

                    <div className="flex items-center border-t-2 border-gray-300">
                        <div className="p-3 mr-5 font-medium text-center text-white w-36 bg-primary">상품등록일</div>

                        <p>{product?.created_at}</p>
                    </div>

                    <div className="flex items-center border-t-2 border-b-2 border-gray-300">
                        <div className="p-3 mr-5 font-medium text-center text-white w-36 bg-primary f-center">
                            작가명
                        </div>

                        <p>{brandname}</p>
                    </div>
                </div>

                <form className="pb-8">
                    <div className="category">
                        <h5 className="mb-4 font-medium text-primary">카테고리</h5>
                        <div className="flex items-center gap-8 pl-8">
                            <div className="flex items-center w-full">
                                <label
                                    htmlFor="mainCategory"
                                    className="relative w-20 mr-6 font-medium whitespace-nowrap"
                                >
                                    대 분 류<span className="absolute top-0 w-1 h-1 rounded-full bg-primary"></span>
                                </label>
                                <Select
                                    value={product.cate}
                                    className="!w-full"
                                    name="cate"
                                    onChange={handleChangeData}
                                >
                                    <option value="0">스티커</option>
                                    <option value="1">다이어리</option>
                                </Select>
                            </div>
                            <div className="flex items-center w-full">
                                <label
                                    htmlFor="mainCategory"
                                    className="relative w-20 mr-6 font-medium whitespace-nowrap"
                                >
                                    소 분 류<span className="absolute top-0 w-1 h-1 rounded-full bg-primary"></span>
                                </label>
                                <Select
                                    value={product.subcate}
                                    className="!w-full"
                                    name="subcate"
                                    onChange={handleChangeData}
                                >
                                    {product.cate === "0"
                                        ? subcate.sticker.map((item) => (
                                              <option key={item.value} value={item.value}>
                                                  {item.name}
                                              </option>
                                          ))
                                        : subcate.diary.map((item) => (
                                              <option key={item.value} value={item.value}>
                                                  {item.name}
                                              </option>
                                          ))}
                                </Select>
                            </div>
                        </div>
                    </div>

                    <hr className="my-8 border-gray-200" />

                    <div className="product_details product_name">
                        <h5 className="mb-4 font-medium text-primary">상 품 명</h5>
                        <div className="flex items-center w-full pl-8">
                            <label htmlFor="product_name" className="relative w-20 mr-6 font-medium whitespace-nowrap">
                                한글 상품명<span className="absolute top-0 w-1 h-1 rounded-full bg-primary"></span>
                            </label>
                            <Input
                                variant="outlined"
                                color="gray"
                                placeholder="ex) 자꾸다꾸 스티커"
                                className="!text-font-input"
                                name="name"
                                value={product.name}
                                onChange={handleChangeData}
                            />
                            <p className="ml-4 text-xs text-gray">{product.name?.length}/100</p>
                        </div>
                    </div>

                    <hr className="my-8 border-gray-200" />

                    <div className="product_details product_price">
                        <div className="flex items-center gap-4 mb-4">
                            <h5 className="font-medium text-primary">상 품 명</h5>
                            <div className="flex items-center gap-2">
                                <RadioButton
                                    color={isFree ? "primary" : "gray"}
                                    checked={isFree}
                                    onClick={() => setIsFree(!isFree)}
                                />
                                <p className={"text-base whitespace-nowrap " + (isFree ? "text-primary" : "text-gray")}>
                                    무료 상품일 경우 선택
                                </p>
                            </div>
                        </div>

                        <div className="flex items-center">
                            <div className="flex items-center w-full pl-8 md:w-1/2">
                                <label htmlFor="product_name" className="relative mr-6 font-medium whitespace-nowrap">
                                    판 매 가<span className="absolute top-0 w-1 h-1 rounded-full bg-primary"></span>
                                </label>
                                <Select
                                    className="w-full max-w-sm disabled:bg-gray-200"
                                    value={product.priceid}
                                    name="priceid"
                                    onChange={handleChangeData}
                                    disabled={isFree}
                                >
                                    {uploadFormData.price_list?.map((pricing, i) => (
                                        <option key={pricing.id || i} value={pricing.id}>
                                            {pricing.price}
                                        </option>
                                    ))}
                                </Select>
                                <p className="mx-4">원</p>
                            </div>
                        </div>
                    </div>

                    <hr className="my-8 border-gray-200" />

                    <div className="product_details file_registration">
                        <div className="flex items-center gap-2">
                            <h5 className="font-medium text-primary">상품등록</h5>
                        </div>

                        <div className="flex pl-8 mt-6">
                            <h5 className="relative h-full font-medium">
                                파일등록 <span className="absolute top-0 w-1 h-1 rounded-full bg-primary"></span>
                            </h5>

                            <div className="ml-8">
                                <label
                                    htmlFor="selectedFile"
                                    className="px-3 py-1 border rounded-full text-gray border-gray"
                                >
                                    파일 선택
                                </label>
                                <input
                                    type="file"
                                    id="selectedFile"
                                    onChange={handleAddFile}
                                    className="hidden"
                                    multiple
                                />

                                <div className="flex flex-col gap-2 mt-4">
                                    {uploadFormData?.files?.map((file) => (
                                        <div className="flex items-center gap-3" key={file.id}>
                                            <Button
                                                variant="outlined"
                                                color="gray"
                                                size="sm"
                                                className="w-full max-w-xs text-left text-gray-700"
                                                hover="off"
                                                onClick={() => handleOpenFile(file.id)}
                                            >
                                                {file.orgname}
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="gray"
                                                size="sm"
                                                icon
                                                onClick={() => handleSelectedFileId(file)}
                                            >
                                                <BsTrash />
                                            </Button>
                                        </div>
                                    ))}
                                    {newFiles &&
                                        newFiles?.map((file, i) => (
                                            <div className="flex items-center gap-3" key={i}>
                                                <Button
                                                    variant="outlined"
                                                    color="gray"
                                                    size="sm"
                                                    className="w-full max-w-xs text-left text-gray-700"
                                                    hover="off"
                                                    onClick={() =>
                                                        product.cate === "0"
                                                            ? handleOpenFileInNewWindow(newFiles, i)
                                                            : handleCreateOpenFilePdf(file, file.type)
                                                    }
                                                >
                                                    {file.name}
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="gray"
                                                    size="sm"
                                                    icon
                                                    onClick={() => handleDeleteToNewFiles(i)}
                                                >
                                                    <BsTrash />
                                                </Button>
                                            </div>
                                        ))}
                                </div>

                                <div className="mt-4 text-xs list-none text-gray">
                                    {product.cate === "0" ? (
                                        <li className="text-primary">· png 파일형식 필수 </li>
                                    ) : (
                                        <li className="text-primary">· pdf 파일형식 필수 </li>
                                    )}
                                    <li>
                                        · <span className="text-primary">최대 10개</span> 등록 가능
                                    </li>
                                    <li>· 최대 3000px(300dpi)</li>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr className="my-8 border-gray-200" />

                    <div className="product_details">
                        <div className="flex items-center gap-2">
                            <h5 className="font-medium text-primary">상품정보</h5>
                        </div>

                        <div className="flex pl-8 mt-6">
                            <h5 className="relative flex-shrink-0 h-full font-medium">
                                상품 이미지
                                <span className="absolute top-0 w-1 h-1 rounded-full bg-primary"></span>
                            </h5>

                            <div className="ml-8">
                                <div className="flex flex-wrap">
                                    {newMainThumb ? (
                                        <div className="relative w-32 h-32 overflow-hidden group">
                                            {" "}
                                            <div
                                                role="button"
                                                className="w-32 h-32 overflow-hidden border cursor-pointer border-primary rounded-3xl f-center"
                                            >
                                                <img src={URL.createObjectURL(newMainThumb)} />
                                            </div>
                                            <div className="w-32 h-32 justify-end px-2 py-2 rounded-3xl absolute top-0 bottom-0 left-0 right-0 bg-[rgba(225,225,225,0.3)] z-20 hidden group-hover:flex">
                                                <div>
                                                    {" "}
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        icon
                                                        size="sm"
                                                        onClick={handleOpenEditMainthumb}
                                                    >
                                                        <FiEdit />
                                                    </Button>
                                                    <input
                                                        type="file"
                                                        className="hidden"
                                                        id="mainThumbChange"
                                                        onChange={handleChangeMainThumb}
                                                    />
                                                </div>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    icon
                                                    size="sm"
                                                    className="ml-2"
                                                    onClick={() => setNewMainThumb(null)}
                                                >
                                                    <BsTrash />
                                                </Button>
                                            </div>
                                        </div>
                                    ) : product.mainthumb && product.mainthumb !== "" ? (
                                        <div className="relative w-32 h-32 mr-4 overflow-hidden group">
                                            {" "}
                                            <div
                                                role="button"
                                                className="w-32 h-32 overflow-hidden border cursor-pointer border-primary rounded-3xl f-center"
                                            >
                                                <ProductThumbnail url={product.mainthumb} />
                                            </div>
                                            <div className="justify-end px-2 py-2 rounded-3xl absolute top-0 right-0 bg-[rgba(225,225,225,0.3)] z-20 hidden group-hover:flex">
                                                <div>
                                                    {" "}
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        icon
                                                        size="sm"
                                                        onClick={handleOpenEditMainthumb}
                                                    >
                                                        <FiEdit />
                                                    </Button>
                                                    <input
                                                        type="file"
                                                        className="hidden"
                                                        id="mainThumbChange"
                                                        onChange={handleChangeMainThumb}
                                                    />
                                                </div>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    icon
                                                    size="sm"
                                                    className="ml-2"
                                                    onClick={() => setNewMainThumb(null)}
                                                >
                                                    <BsTrash />
                                                </Button>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <label htmlFor="mainThumb">
                                                <div
                                                    role="button"
                                                    className="w-32 h-32 border cursor-pointer border-primary rounded-3xl f-center"
                                                >
                                                    <BsPlusLg className="text-4xl text-primary font-fullbold" />
                                                </div>
                                            </label>
                                            <input
                                                type="file"
                                                id="mainThumb"
                                                className="hidden"
                                                onChange={handleChangeMainThumb}
                                            />
                                        </>
                                    )}
                                    {allSubThumbs && allSubThumbs.length !== 0
                                        ? allSubThumbs
                                              .filter((thumb) => thumb.type !== "delete")
                                              .sort((a, b) => a.index - b.index)
                                              .map((thumb, i) => {
                                                  return (
                                                      <div className="relative w-32 h-32 mb-2 mr-4 group" key={i}>
                                                          <div
                                                              role="button"
                                                              className="w-32 h-32 overflow-hidden border cursor-pointer border-gray rounded-3xl f-center"
                                                              aria-disabled="true"
                                                          >
                                                              {(thumb.type === "add" || thumb.type === "change") && (
                                                                  <img
                                                                      src={URL.createObjectURL(thumb.file)}
                                                                      alt=""
                                                                      onClick={() => {
                                                                          handleOpenFileInNewWindowAdd(thumb.file, i);
                                                                      }}
                                                                  />
                                                              )}

                                                              {thumb.type === "old" && (
                                                                  <ProductThumbnail url={thumb.imgsrc} type="full" />
                                                              )}
                                                          </div>
                                                          <div className="justify-end px-2 py-2 rounded-3xl absolute top-0 right-0 bg-[rgba(225,225,225,0.3)] z-20 hidden group-hover:flex">
                                                              <div>
                                                                  {" "}
                                                                  <Button
                                                                      variant="contained"
                                                                      color="primary"
                                                                      icon
                                                                      size="sm"
                                                                      onClick={() => handleOpenEditSubthumb(i)}
                                                                  >
                                                                      <FiEdit />
                                                                  </Button>
                                                                  <input
                                                                      type="file"
                                                                      className="hidden"
                                                                      id={`subThumbChange-${i}`}
                                                                      onChange={(e) => handleAddSubThumbs(e, i, thumb)}
                                                                  />
                                                              </div>

                                                              <Button
                                                                  variant="contained"
                                                                  color="primary"
                                                                  icon
                                                                  size="sm"
                                                                  className="ml-2"
                                                                  onClick={(e) => handleDeleteSubThumbs(e, i, thumb)}
                                                              >
                                                                  <BsTrash />
                                                              </Button>
                                                          </div>
                                                      </div>
                                                  );
                                              })
                                        : null}
                                    <label htmlFor="thumb">
                                        <div
                                            role="button"
                                            className="w-32 h-32 mr-4 border cursor-pointer border-gray rounded-3xl f-center"
                                            aria-disabled="true"
                                        >
                                            <BsPlusLg className="text-4xl text-gray font-fullbold" />
                                        </div>
                                    </label>
                                    <input
                                        type="file"
                                        id="thumb"
                                        className="hidden"
                                        multiple
                                        onChange={(e) => handleAddNewSubThumbs(e, "add")}
                                    />{" "}
                                </div>

                                <div className="mt-4 text-xs list-none text-gray">
                                    <li className="text-primary">
                                        · 대표 이미지를 첫번째로 등록 <span className="text-gray">(대표 이미지는</span>{" "}
                                        필수
                                        <span className="text-gray">에요)!</span>{" "}
                                    </li>
                                    <li>
                                        · 최대크기 <span className="text-primary">1280x1280px</span>
                                    </li>
                                    <li>
                                        · <span className="text-primary">최대 25장</span> 등록 가능
                                    </li>
                                    <li>
                                        · <span className="text-primary">jpg / jpeg / png</span> 파일형식
                                    </li>
                                </div>
                            </div>
                        </div>

                        <div className="flex pl-8 mt-6">
                            <h5 className="h-full font-medium">한글 상품설명</h5>

                            <div className="w-full max-w-2xl ml-8">
                                <TextArea
                                    variant="outlined"
                                    color="gray"
                                    rows={5}
                                    name="inst"
                                    value={product.inst}
                                    onChange={handleChangeData}
                                    className="!text-font-input"
                                />
                                <p className="mt-1 font-normal text-end text-gray">{product.inst.length}/1000</p>
                            </div>
                        </div>

                        <div className="flex pl-8 mt-6">
                            <h5 className="h-full font-medium">상품필터</h5>

                            <div className="w-full max-w-3xl ml-8">
                                <div className="filters_type">
                                    <h6 className="tracking-[10px] font-medium">분 류</h6>

                                    <div className="flex flex-wrap items-center gap-4 px-4 py-3 mt-4 border border-gray-300 rounded-2xl md:px-6">
                                        {productFiltersData[productFiltersData[product.cate]].type.map((type, i) => {
                                            const bool = product.ftypes.includes(parseInt(type.id));
                                            return (
                                                <Button
                                                    key={type.id || i}
                                                    variant={bool ? "contained" : "outlined"}
                                                    color={bool ? "primary" : "gray"}
                                                    size="sm"
                                                    className={bool && "border border-transparent"}
                                                    onClick={(e) => handleClickToggleFTypes(e, type)}
                                                >
                                                    {type.name}
                                                </Button>
                                            );
                                        })}
                                    </div>
                                </div>

                                <div className="mt-8 filters_mood">
                                    <h6 className="font-medium">분위기</h6>

                                    <div className="flex flex-wrap items-center gap-4 px-4 py-3 mt-4 border border-gray-300 rounded-2xl md:px-6">
                                        {productFiltersData[productFiltersData[product.cate]].mood.map((mood, i) => {
                                            const bool = product.fmoods.indexOf(parseInt(mood.id)) > -1;
                                            return (
                                                <Button
                                                    key={mood.id || i}
                                                    variant={bool ? "contained" : "outlined"}
                                                    color={bool ? "primary" : "gray"}
                                                    size="sm"
                                                    className={bool && "border border-transparent"}
                                                    onClick={(e) => handleClickToggleFMoods(e, mood)}
                                                >
                                                    {mood.name}
                                                </Button>
                                            );
                                        })}
                                    </div>
                                </div>

                                <div className="mt-8 color">
                                    <h6 className="font-medium">색 상</h6>

                                    <div className="flex flex-wrap items-center gap-4 px-4 py-3 border border-gray-300 rounded-2xl md:px-6">
                                        {colors.map((color, i) => (
                                            <div
                                                key={`color_${i}`}
                                                className={
                                                    "h-7 w-7 f-center rounded-full " +
                                                    (product.colorsId.includes(parseInt(color.id))
                                                        ? " border-2 border-red-500"
                                                        : " border border-gray-200")
                                                }
                                                style={{ background: "#" + color.rgb_code }}
                                                onClick={(e) => handleToggleColor(e, color)}
                                            ></div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex pl-8 mt-12">
                            <h5 className="flex-shrink-0 h-full font-medium md:mt-2">상품태그</h5>

                            <div className="w-full max-w-3xl ml-8 tags">
                                {/* <Input
                                    variant="outlined"
                                    color="gray"
                                    placeholder="#어울리는 태그를 입력해주세요 (최대10개)"
                                    name="tags"
                                    value={product.tags}
                                    onChange={handleChangeData}
                                    className="!text-font-input"
                                /> */}
                                <Tagify
                                    settings={settings}
                                    value={product.tags}
                                    onChange={onHashTagChange}
                                    placeholder="#어울리는 태그를 입력해주세요 (최대10개)"
                                />

                                <div className="flex items-start mt-4">
                                    <ul className="mr-6">
                                        <li className="font-normal text-primary"> · 엔터로 구분</li>
                                        {/* <li className="font-normal text-primary"> · 스페이스로 구분</li>
                                        <li className="font-normal text-gray">· ‘#’은 자동입력</li> */}
                                    </ul>
                                    {/* <ul className="flex space-x-2">
                                        {product?.tags?.split(" ").map((tag, i) => (
                                            <li
                                                key={i}
                                                className="px-4 py-1 text-sm font-normal bg-gray-100 rounded-full"
                                            >
                                                #{tag}
                                            </li>
                                        ))}
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr className="my-8 border-gray-200" />

                    <div className="product_details">
                        <div className="flex gap-4 pl-8">
                            <h5 className="font-medium whitespace-nowrap">반려사유</h5>

                            <div className="w-full max-w-3xl ml-4">
                                <TextArea
                                    variant="outlined"
                                    color="gray"
                                    rows={3}
                                    value={comment}
                                    onChange={(e) => setComment(e.target.value)}
                                    className="!text-font-input"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="gap-6 mt-16 submit_buttons f-center">
                        <Button
                            variant="contained"
                            color="primary"
                            size="lg"
                            onClick={handleClickCompleteProduct}
                            disabled={page.creating}
                        >
                            승인완료
                        </Button>
                        <Button variant="outlined" color="primary" size="lg" onClick={handleClickRejectProduct}>
                            승인반려
                        </Button>
                    </div>
                </form>
            </section>
        </div>
    );
};

export default AddProduct;
