import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Input } from "../../../components";

import logo from "../../../static/img/logo.svg";
import cookieHelper from "../../../utils/cookieHelper";
import { signIn } from "../../../apis";
import AuthContext from "../../../contexts/authContext";

const Login = () => {
    const navigate = useNavigate();
    const [message, setMessage] = useState("");
    const [formDetails, setFormDetails] = useState({ email: "", password: "" });
    const { setAuthData } = useContext(AuthContext);

    const handleChangeFormDetails = (e) => {
        const { name, value } = e.target;
        setFormDetails({ ...formDetails, [name]: value });
    };

    const handleKeyPressLogin = (e) => {
        if (e.key === "Enter") {
            handleClickLogin();
        }
    };

    const handleClickLogin = async (e) => {
        try {
            const { data } = await signIn({
                data: {
                    email: formDetails.email.trim(),
                    pwd: formDetails.password.trim(),
                },
            });

            if (data.status > 299 || data.status < 200) {
                const message = data && data.error_string ? data.error_string : "Something went wrong";
                setMessage(message);
                throw new Error(message);
            }

            if (parseInt(data.user.level) < 90) {
                setMessage("관리자 계정이 맞는지 확인해주세요");
                return;
            }

            setAuthData({
                token: data.user.access_token,
                loading: false,
                status: true,
            });
            cookieHelper.setCookie("admin_access_token", data.user.access_token, "1");
            navigate("/admin/dashboard");
        } catch (err) {
            setMessage(err.response.data.error_string);
            console.log(err);
        }
    };

    return (
        <div className="w-full min-h-screen overflow-auto login_page f-center">
            <div className="w-full max-w-xs p-4">
                <div className="mb-10 f-center">
                    <img src={logo} alt="logo" width="280px" />
                </div>
                <div className="login_form">
                    <div className="w-full mt-8 inputs">
                        <Input
                            className="mb-5 !text-font-input"
                            variant="outlined"
                            color="gray"
                            placeholder="이메일을 입력해주세요"
                            name="email"
                            type="email"
                            onChange={handleChangeFormDetails}
                        />
                        <Input
                            className="mb-2 !text-font-input"
                            variant="outlined"
                            color="gray"
                            placeholder="비밀번호를 입력해주세요"
                            name="password"
                            type="password"
                            onChange={handleChangeFormDetails}
                            onKeyPress={handleKeyPressLogin}
                        />
                    </div>
                    <div className="inputs w-full max-w-[280px] flex flex-col mt-5">
                        <p className="mb-3 text-xs text-center text-primary">{message || ""}</p>
                        <Button
                            className="mb-4"
                            variant="contained"
                            color={
                                formDetails?.email?.trim() === "" || formDetails?.password?.trim() === ""
                                    ? "grayLight"
                                    : "primary"
                            }
                            hover="off"
                            size="lgs"
                            disabled={formDetails?.email?.trim() === "" || formDetails?.password?.trim() === ""}
                            onClick={handleClickLogin}
                        >
                            관리자 로그인
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
