import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slick.css";

export default function Carousel({ banners }) {
    const setting = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dotsClass: "dot-custom",
    };
    return (
        <section className="slider mx-auto">
            <Slider {...setting}>
                {banners.map((banner) => (
                    <div key={banner.id}>
                        <div className="max-w-[816px] flex items-center w-full bg-gray-200 h-auto mx-auto rounded-3xl overflow-hidden">
                            <img src={banner?.imgsrc} alt="" />
                        </div>
                    </div>
                ))}
            </Slider>
        </section>
    );
}
