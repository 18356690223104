import React, { useEffect, useState } from "react";
import { BsDot } from "react-icons/bs";
import { Link } from "react-router-dom";
import { Button, Input } from "../../components";
import { deleteBanner, getBanners } from "../../apis";
import formatDate from "../../utils/formatDate";
import Banner from "../../components/Banner/Banner";
import Carousel from "../../components/Banner/Carousel";

const BannerManagement = () => {
    const [banners, setBanners] = useState({
        ads: [],
        expiration: [],
    });
    const [editData, setEditData] = useState({}); // State to manage edit data

    const fetchBanners = async () => {
        try {
            const response = await fetch("https://zcdc-excel.teamcredit.kr/fa/admin/banners");
            const data = await response.json();
            if (response.ok) {
                const now = new Date();
                const activeBanners = [];
                const expiredBanners = [];

                data.ads.forEach(banner => {
                    const endDate = new Date(banner.end_at);
                    if (endDate > now) {
                        activeBanners.push(banner);
                    } else {
                        expiredBanners.push(banner);
                    }
                });

                setBanners({
                    ads: activeBanners,
                    expiration: expiredBanners,
                });
            } else {
                throw new Error(data.error_string || "Error fetching banners");
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const handleInputChange = (id, field, value) => {
        setEditData(prev => ({ ...prev, [id]: { ...prev[id], [field]: value } }));
    };

    const handleSave = async (bannerId) => {
        const bannerData = editData[bannerId];
        try {
            const response = await fetch(`https://zcdc-excel.teamcredit.kr/fa/admin/banners/${bannerId}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(bannerData)
            });
            if (response.ok) {
                alert('Banner updated successfully');
                // Optionally refresh the banners list or handle UI update
            } else {
                throw new Error('Failed to update banner');
            }
        } catch (error) {
            console.error('Save error:', error);
            alert('Error updating banner');
        }
    };

    const handleDeleteBanner = async (id) => {
        try {
            const response = await fetch(`https://zcdc-excel.teamcredit.kr/fa/admin/banners/${id}`, {
                method: "DELETE",
            });
            const data = await response.json();
            if (response.ok) {
                // Filter out the banner with the given id
                const updatedExpirationList = banners.expiration.filter((banner) => banner.id !== id);
                setBanners((prevBanners) => ({
                    ...prevBanners,
                    expiration: updatedExpirationList,
                }));
            } else {
                throw new Error(data.error_string || "Error deleting banner");
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    useEffect(() => {
        fetchBanners();
    }, []);

    return (
        <div className="banner_page">
            <section className="page_header flex items-center gap-4">
                <Link to="/admin/setting/banner">
                    <Button variant="contained" color="primary" hover="off">
                        배너관리
                    </Button>
                </Link>
                <Link to="/admin/setting/popup">
                    <Button color="black">팝업관리</Button>
                </Link>
                <Link to="/admin/setting/notice">
                    <Button color="black">공지 관리</Button>
                </Link>
            </section>

            <hr className="border-gray-200 mt-4 mb-10" />

            <Carousel banners={banners.ads} />

            <hr className="border-transparent my-4" />

            <section>
                <h3 className="text-lg pl-3 mb-2">게시중</h3>

                <div className="relative overflow-x-auto">
                    <div className="table w-full border border-gray-200 text-center text-sm min-w-[1200px] pb-3">
                        <div className="w-full border-b border-b-gray-200">
                            <div className="grid grid-cols-12 w-full">
                                <p className="table-cell px-6 py-3 border-r border-r-gray-200 last:border-l-0">순서</p>
                                <p className="table-cell col-span-2 px-6 py-3 border-r border-r-gray-200 last:border-l-0">
                                    배너이름
                                </p>
                                <p className="table-cell col-span-3 px-6 py-3 border-r border-r-gray-200 last:border-l-0">
                                    URL
                                </p>
                                <p className="table-cell px-6 py-3 border-r border-r-gray-200 last:border-l-0">
                                    배경색
                                </p>
                                <p className="table-cell px-6 py-3 border-r border-r-gray-200 last:border-l-0">
                                    시작일
                                </p>
                                <p className="table-cell px-6 py-3 border-r border-r-gray-200 last:border-l-0">
                                    종료일
                                </p>
                                <p className="table-cell col-span-2 px-6 py-3 border-r border-r-gray-200 last:border-l-0">
                                    파일첨부
                                </p>
                                <p className="table-cell px-6 py-3 border-r border-r-gray-200 last:border-l-0">저장</p>
                            </div>
                        </div>
                        <div>
                            {banners?.ads?.map((banner) => (
                                <Banner key={banner.id} banner={banner} type="ad" />
                            ))}
                        </div>
                    </div>
                </div>
            </section>

            <hr className="border-transparent my-8" />

            <section>
                <h3 className="text-lg pl-3 mb-2">게시만료</h3>

                <div className="relative overflow-x-auto">
                    <div className="table w-full border border-gray-200 text-center text-sm min-w-[1200px] pb-3">
                        <div className="w-full border-b border-b-gray-200">
                            <div className="grid grid-cols-12 w-full">
                                <p className="table-cell px-6 py-3 border-r border-r-gray-200 last:border-l-0">삭제</p>
                                <p className="table-cell col-span-2 px-6 py-3 border-r border-r-gray-200 last:border-l-0">
                                    배너이름
                                </p>
                                <p className="table-cell col-span-3 px-6 py-3 border-r border-r-gray-200 last:border-l-0">
                                    URL
                                </p>
                                <p className="table-cell px-6 py-3 border-r border-r-gray-200 last:border-l-0">
                                    배경색
                                </p>
                                <p className="table-cell px-6 py-3 border-r border-r-gray-200 last:border-l-0">
                                    시작일
                                </p>
                                <p className="table-cell px-6 py-3 border-r border-r-gray-200 last:border-l-0">
                                    종료일
                                </p>
                                <p className="table-cell col-span-2 px-6 py-3 border-r border-r-gray-200 last:border-l-0">
                                    파일첨부
                                </p>
                                <p className="table-cell px-6 py-3 border-r border-r-gray-200 last:border-l-0">저장</p>
                            </div>
                        </div>
                        <div>
                            {banners?.expiration.map((banner) => (
                                <Banner key={banner.id} banner={banner} type="expiration" />
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default BannerManagement;
