import { Link } from "react-router-dom";
import { Button, Input, Pagination, RadioButton, Select } from "../../components";
import excelIcon from "../../static/img/excel.png";
import { useEffect, useState } from "react";
import { getUser, getUserList } from "../../apis";
import formatDate from "../../utils/formatDate";
import { formatUserState } from "../../utils/formatData";

const MemberList = () => {
    const [users, setUsers] = useState([]);
    const [isSearch, setIsSearch] = useState(false);
    const [searchValue, setSearchValue] = useState({
        sdate: null,
        edate: null,
    });

    const [page, setPage] = useState({
        currentPage: 1,
        pageSize: 15,
        totalCount: 0,
        loading: false,
    });

    const handleChangeData = (e) => {
        let { name, value } = e.target;
        setSearchValue({ ...searchValue, [name]: value });
    };

    const handlePageChange = (value) => {
        if (value === page.currentPage) return;
        setPage({ ...page, currentPage: value });
    };

    const handleSearch = (e) => {
        e.preventDefault();
        setIsSearch(true);
        setPage({ ...page, currentPage: 1 });
        const { sdate, edate } = searchValue;

        fetchingUsers({
            start: sdate,
            end: edate,
            page: 1,
        });
    };

    const fetchingUsers = async (params, headers) => {
        setPage({ ...page, loading: true });
        try {
            const { data } = await getUserList(params, headers);
            const { page, perPage, total } = data;

            if (data.status > 299 || data.status < 200) {
                const message = data && data.message ? data.message : "Something went wrong";
                throw new Error(message);
            }

            setUsers(data.users);
            setPage({
                ...page,
                currentPage: Number(page),
                pageSize: Number(perPage),
                totalCount: Number(total),
                loading: false,
            });
        } catch (error) {
            setPage({ ...page, loading: false });
            console.log(error);
        }
    };

    useEffect(() => {
        if (!isSearch) {
            fetchingUsers({
                page: page.currentPage,
                per_page: page.pageSize,
            });
        } else {
            const { sdate, edate } = searchValue;
            fetchingUsers({
                start: sdate,
                end: edate,
                page: page.currentPage,
            });
        }
    }, [page.currentPage, isSearch]);

    const handleExcelDownload = () => {
        // Adjust the URL to your endpoint for downloading the members' Excel file
        fetch("https://zcdc-excel.teamcredit.kr/fa/admin/users/excel/download")
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                const date = new Date();
                const formattedDate = date.toISOString().slice(2, 10).replace(/-/g, '');
                // Adjust the filename as needed
                const filename = `어드민회원목록-${formattedDate}.xlsx`;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => console.error('Download failed', error));
    };

    return (
        <div className="member_management_page">
            <div className="heading">
                <h2 className="text-xl font-medium">회원관리</h2>
            </div>

            <hr className="border-gray-200 my-6" />

            <section className="filters_container">
                <div className="filters mb-8">
                    <div className="flex items-center border-b-2 border-t-2 border-gray-300">
                        <div className="w-36 text-center text-white p-3 bg-primary mr-5 font-medium">기간검색</div>

                        <div className="flex items-center gap-8 text-lg">
                            <Input
                                type="date"
                                variant="outlined"
                                color="gray"
                                name="sdate"
                                value={searchValue.sdate || ""}
                                onChange={handleChangeData}
                            />
                            <span>~</span>
                            <Input
                                type="date"
                                variant="outlined"
                                color="gray"
                                name="edate"
                                value={searchValue.edate || ""}
                                onChange={handleChangeData}
                            />
                        </div>
                    </div>
                </div>
                <div className="action f-center">
                    <Button variant="contained" color="primary" size="lgs" onClick={handleSearch}>
                        검 <span className="mx-2"></span> 색
                    </Button>
                </div>
            </section>

            <section className="sales_records mt-10">
                <div className="flex items-center mb-3">
                    <h5 className="text-gray">검색개수 : {page.totalCount}건</h5>
                    <div className="ml-auto">
                        <Button variant="outlined" color="green" size="sm" className="!rounded-full f-center" onClick={handleExcelDownload}>
                            <img
                                src={excelIcon}
                                alt="excel"
                                height="19px"
                                width="19px"
                                className="object-contain mr-2"
                            />
                            엑셀로 내려받기
                        </Button>
                    </div>
                </div>

                <div className="relative overflow-x-auto">
                    <table className="w-full text-sm text-left text-black border border-gray-300 whitespace-nowrap">
                        <thead className="text-base bg-gray-100 border-b border-b-gray-300">
                            <tr>
                                <th
                                    scope="col"
                                    className="px-6 py-4 text-center font-medium border-r border-r-gray-300"
                                >
                                    No
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-4 text-center font-medium border-r border-r-gray-300"
                                >
                                    기기고유번호
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-4 text-center font-medium border-r border-r-gray-300"
                                >
                                    총 구매상품 수
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-4 text-center font-medium border-r border-r-gray-300"
                                >
                                    총 결제금액
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-4 text-center font-medium border-r border-r-gray-300"
                                >
                                    가입일
                                </th>
                            </tr>
                        </thead>
                        <tbody className="font-normal">
                            {users.map((member) => (
                                <tr key={member.id} className="bg-white border-b border-gray-300 last:border-b-0">
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300">
                                        {member.id || "-"}
                                    </td>

                                    <td className="px-6 py-3 text-center border-r border-r-gray-300 text-primary underline underline-offset-2">
                                        <Link to={`/admin/members/list/${member.id}`} state={{ uuid: member.uuid }}>
                                            {" "}
                                            {member.uuid || "-"}
                                        </Link>
                                    </td>

                                    <td className="px-6 py-3 text-center border-r border-r-gray-300">
                                        {member.purchasedProductCount || "-"}
                                    </td>
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300">
                                        {member.amountPayment || "-"}
                                    </td>
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300">
                                        {formatDate(member.createdAt) || "-"}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </section>

            <section className="pagination mt-5 f-center">
                <Pagination
                    pageSize={page.pageSize}
                    onPageChange={handlePageChange}
                    totalCount={page.totalCount}
                    currentPage={page.currentPage}
                />
            </section>
        </div>
    );
};

export default MemberList;
