import { Link } from "react-router-dom";
import { Button, Input, Pagination, RadioButton, Select } from "../../components";
import excelIcon from "../../static/img/excel.png";
import { useEffect, useState } from "react";
import { getDesignerList } from "../../apis";
import formatDate from "../../utils/formatDate";
import { formatBusinessType, formatUserState } from "../../utils/formatData";

const AuthorList = () => {
    const [designers, setDesigners] = useState([]);
    const [isSearch, setIsSearch] = useState(false);
    const [searchValue, setSearchValue] = useState({
        status: "ALL",
        searchType: "NAME",
        sw: null,
        sdate: null,
        edate: null,
    });
    const [page, setPage] = useState({
        currentPage: 1,
        pageSize: 15,
        totalCount: 0,
        loading: false,
    });

    const handleChangeStatus = (value) => {
        setSearchValue({ ...searchValue, status: value });
    };

    const handleChangeData = (e) => {
        let { name, value } = e.target;
        setSearchValue({ ...searchValue, [name]: value });
    };

    const handleSearch = (e) => {
        e.preventDefault();
        setIsSearch(true);
        setPage({ ...page, currentPage: 1 });
        const { status, sdate, edate, sw, searchType } = searchValue;

        fetchingDesigners({
            status,
            sdate,
            edate,
            sw,
            searchType,
            page: 1,
        });
    };

    const handlePageChange = (value) => {
        if (value === page.currentPage) return;
        setPage({ ...page, currentPage: value });
    };

    const fetchingDesigners = async (params, headers) => {
        setPage({ ...page, loading: true });
        try {
            const { data } = await getDesignerList(params, headers);
            const { page, per_page, item_count } = data;

            if (data.status > 299 || data.status < 200) {
                const message = data && data.message ? data.message : "Something went wrong";
                throw new Error(message);
            }
            setDesigners(data.items);
            setPage({
                ...page,
                currentPage: Number(page),
                pageSize: Number(per_page),
                totalCount: Number(item_count),
                loading: false,
            });
        } catch (error) {
            setPage({ ...page, loading: false });
            console.log(error);
        }
    };

    const handleExcelDownload = () => {
        fetch("https://zcdc-excel.teamcredit.kr/fa/admin/designers/excel/download")
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                const date = new Date();
                const formattedDate = date.toISOString().slice(2,10).replace(/-/g, '');
                const filename = `어드민작가목록-${formattedDate}.xlsx`;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            })
            .catch(error => console.error('Download failed', error));
    };

    useEffect(() => {
        if (!isSearch) {
            fetchingDesigners({
                page: page.currentPage,
                per_page: page.pageSize,
            });
        } else {
            const { status, sdate, edate, sw, searchType } = searchValue;
            fetchingDesigners({
                status,
                sdate,
                edate,
                sw,
                searchType,
                page: page.currentPage,
            });
        }
    }, [page.currentPage, isSearch]);

    return (
        <div className="author_list_page">
            <div className="heading">
                <h2 className="text-xl font-medium">작가관리</h2>
            </div>

            <hr className="my-6 border-gray-200" />

            <section className="filters_container">
                <div className="mb-8 filters">
                    <div className="flex items-center border-t border-gray-300">
                        <div className="p-3 mr-5 font-medium text-center text-white w-36 f-center bg-primary">
                            상 <span className="block mx-3"></span> 태
                        </div>

                        <div className="flex items-center gap-8 text-lg">
                            <div className="flex items-center gap-2">
                                <RadioButton
                                    checked={searchValue.status === "ALL"}
                                    onClick={() => handleChangeStatus("ALL")}
                                />
                                <p>전체</p>
                            </div>

                            <div className="flex items-center gap-2">
                                <RadioButton
                                    checked={searchValue.status === "NORMAL"}
                                    onClick={() => handleChangeStatus("NORMAL")}
                                />
                                <p>정상</p>
                            </div>

                            <div className="flex items-center gap-2">
                                <RadioButton
                                    checked={searchValue.status === "RETIRE"}
                                    onClick={() => handleChangeStatus("RETIRE")}
                                />
                                <p>탈퇴</p>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center border-t-2 border-gray-300">
                        <div className="p-3 mr-5 font-medium text-center text-white w-36 bg-primary">기간검색</div>

                        <div className="flex items-center gap-8 text-lg">
                            <Input
                                type="date"
                                variant="outlined"
                                color="gray"
                                name="sdate"
                                value={searchValue.start || ""}
                                onChange={handleChangeData}
                            />
                            <span>~</span>
                            <Input
                                type="date"
                                variant="outlined"
                                color="gray"
                                name="edate"
                                value={searchValue.end || ""}
                                onChange={handleChangeData}
                            />
                        </div>
                    </div>

                    <div className="flex items-center border-t-2 border-b-2 border-gray-300">
                        <div className="p-3 mr-5 font-medium text-center text-white w-36 bg-primary f-center">
                            검 <span className="block mx-3"></span> 색
                        </div>

                        <div className="flex items-center w-full max-w-md gap-2 text-lg">
                            <div className="max-w-[120px] w-full">
                                <Select
                                    defaultValue="NAME"
                                    name="searchType"
                                    onChange={handleChangeData}
                                    className="w-full"
                                >
                                    <option value="NAME">작가명</option>
                                    <option value="USER_NAME">성함</option>
                                    <option value="EMAIL">이메일</option>
                                </Select>
                            </div>
                            <div className="w-full max-w-sm">
                                <Input
                                    variant="outlined"
                                    color="gray"
                                    name="sw"
                                    value={searchValue.sw || ""}
                                    onChange={handleChangeData}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="action f-center">
                    <Button variant="contained" color="primary" size="lgs" onClick={handleSearch}>
                        검 <span className="mx-2"></span> 색
                    </Button>
                </div>
            </section>

            <section className="mt-10 sales_records">
                <div className="flex items-center mb-3">
                    <h5 className="text-gray">검색개수 : {page.totalCount}건</h5>
                    <div className="ml-auto">
                        <Button variant="outlined" color="green" size="sm" className="!rounded-full f-center" onClick={handleExcelDownload}>
                            <img
                                src={excelIcon}
                                alt="excel"
                                height="19px"
                                width="19px"
                                className="object-contain mr-2"
                            />
                            엑셀로 내려받기
                        </Button>
                    </div>
                </div>

                <div className="relative overflow-x-auto">
                    <table className="w-full text-sm text-left text-black border border-gray-300 whitespace-nowrap">
                        <thead className="text-base bg-gray-100 border-b border-b-gray-300">
                            <tr>
                                <th
                                    scope="col"
                                    className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                                >
                                    No
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                                >
                                    이메일
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                                >
                                    성명
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                                >
                                    작가명(국문)
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                                >
                                    작가명(영문)
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                                >
                                    휴대폰번호
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                                >
                                    상품등록수
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                                >
                                    사업자
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                                >
                                    등록일
                                </th>
                                <th scope="col" className="px-6 py-4 font-medium">
                                    상태
                                </th>
                            </tr>
                        </thead>
                        <tbody className="font-normal">
                            {designers.map((member, i) => (
                                <tr key={i} className="bg-white border-b border-gray-300 last:border-b-0">
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300 whitespace-nowrap">
                                        {member.id || "-"}
                                    </td>
                                    <td className="px-6 py-3 text-center underline border-r border-r-gray-300 text-primary">
                                        <Link to={`/admin/author/list/${member.id}`}>{member.email || "-"}</Link>
                                    </td>
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300">
                                        {member.name || "-"}
                                    </td>
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300">
                                        {member.brand_name || "-"}
                                    </td>
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300">
                                        {member.brand_name_en || "-"}
                                    </td>
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300">
                                        {member.phone || "-"}
                                    </td>
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300">
                                        {member.product_cnt}
                                    </td>
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300">
                                        {formatBusinessType(member.business_type) || "-"}
                                    </td>
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300">
                                        {formatDate(member.created_at) || "-"}
                                    </td>
                                    <td
                                        className={
                                            "px-6 py-3 text-center " + (member.is_deleted === true && "text-primary")
                                        }
                                    >
                                        {member.is_blocked ? "정지" : formatUserState(member.is_deleted) || "-"}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </section>

            <section className="mt-5 pagination f-center">
                <Pagination
                    pageSize={page.pageSize}
                    onPageChange={handlePageChange}
                    totalCount={page.totalCount}
                    currentPage={page.currentPage}
                />
            </section>
        </div>
    );
};

export default AuthorList;
