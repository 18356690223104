import axios from "axios";
import config from "../config/config";
import cookieHelper from "../utils/cookieHelper";

const http = axios.create({
    baseURL: config.apiUrl,
});

http.interceptors.request.use((config) => {
    const token = cookieHelper.getCookie("admin_access_token");
    if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
});

// http.defaults.withCredentials = true;

// Interceptor for all outgoing api calls
http.interceptors.request.use((config, _onReject) => {
    return config;
});

export const uploadForm = (params, headers) => http.get("/product/uploadform", { params, headers });

// 상품페이지 APIs
export const getProductList = (params, headers) => http.get("/adminp/reviews", { params, headers });
export const getProduct = (params, headers) => http.get("/adminp/review", { params, headers });
export const removeFile = (data, params, headers) => http.post("/product/delfile", data.data, { params, headers });
export const reviewProduct = (data, params, headers) =>
    http.post(`/admins/products/${data.id}/reviews`, data.data, { params, headers });
export const productFilters = (params, headers) => http.get("/product/ajaxfilters", { params, headers });
export const addProductFile = (data, params, headers) => http.post("/product/addfile", data.data, { params, headers });
export const addProductThumb = (data, params, headers) =>
    http.post("/product/addthumb", data.data, { params, headers });
export const deleteFile = (data, params, headers) => http.post("/product/delfile", data.data, { params, headers });
export const deleteThumb = (data, params, headers) => http.post("/product/delthumb", data.data, { params, headers });
export const getThumbnail = (params, headers) => http.get("/product/getth", { params, headers, responseType: "blob" });
export const getThumbnailFull = (data, params, headers) =>
    http.get(`${data.url}`, { params, headers, responseType: "blob" });
export const createProduct = (data, params, headers) => http.post("/api/products", data.data, { params, headers });

// 작가관리 APIs
export const getDesignerList = (params, headers) => http.get("/admind/list", { params, headers });
export const getDesigner = (params, headers) => http.get("/admind/profile", { params, headers });
export const updateDesigner = (data, params, headers) => http.post("/admind/updatedp", data.data, { params, headers });
// export const getBusinessFile = (params, headers) => http.get("/sales/getbusinessfile", { params, headers });
export const getBusinessFile = (params) =>
    http.get("/sales/getbusinessfile", {
        params,
        responseType: "arraybuffer",
    });
export const updateBusiness = (data, params, headers) =>
    http.post("/user/updatebusiness", data.data, { params, headers });
export const getProfileImage = (params, headers) => http.get("/user/getp", { params, headers, responseType: "blob" });
export const updateProfileImage = (data, params, headers) =>
    http.post("/user/updatedp", data.data, { params, headers });

// 회원관리 APIs
export const signIn = (data, params, headers) => http.post("/api/auth/login", data.data, { params, headers });
export const getUserList = (params, headers) => http.get("/api/admins/users", { params, headers });
export const getUser = (params, headers) => http.get("/admind/user", { params, headers });
export const blockUser = (data, params, headers) => http.post(`/api/users/${data.id}/block`, { params, headers });
export const updateUser = (data, params, headers) => http.post(`/user/editprofileact`, data.data, { params, headers });

// 설정 APIs
export const getBanners = (params, headers) => http.get("/admin/banner", { params, headers });
export const updateBanner = (data, params, headers) => http.post("/admin/updatead", data.data, { params, headers });
export const deleteBanner = (data, params, headers) =>
    http.post(`/admins/banners/${data.id}/delete`, { params, headers });
export const getNotices = (params, headers) => http.get("/v1/notices", { params, headers });
export const addNotice = (data, params, headers) => http.post("/v1/notices", data.data, { params, headers });
export const deleteNotice = (data, params, headers) => http.post(`/v1/notices/${data.id}/delete`, { params, headers });

// 대시보드 APIs
export const getProductsStatus = (params, headers) => http.get("/products/status", { params, headers });
export const getUsersStatus = (params, headers) => http.get("/admins/user-statistics", { params, headers });

// 정산관리 APIs
export const getSettlement = (params, headers) => http.get("/admins/reqs", { params, headers });
export const getSettlementDetail = (params, headers) => http.get("/admins/req", { params, headers });
export const settlementRegister = (withdraw_id, headers) => {
    return http.get("/admins/withdraw-done", { params: { withdraw_id }, headers });
};
