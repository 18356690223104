import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Input, Select, TextArea } from "../../components";
import { addNotice, deleteNotice, getNotices } from "../../apis";
import formatDate from "../../utils/formatDate";

const NoticeManagement = () => {
    const [notices, setNotices] = useState({
        app: [],
        seller: [],
    });

    const [newNotice, setNewNotice] = useState({
        title: "",
        type: "NORMAL",
        content: "",
        category: "APP",
    });

    const handleChangeData = (e) => {
        let { name, value } = e.target;
        setNewNotice({ ...newNotice, [name]: value });
    };

    const fetchNotices = async () => {
        try {
            const { data } = await getNotices();
            if (data.status > 299 || data.status < 200) {
                const message = data && data.message ? data.message : "Something went wrong";
                throw new Error(message);
            }
            setNotices({
                app: data.notices.filter((notice) => notice.category === "APP"),
                seller: data.notices.filter((notice) => notice.category === "SELLER"),
            });
        } catch (err) {
            console.log(err);
        }
    };

    const handleAddNotice = async () => {
        try {
            const formData = new FormData();
            formData.append("title", newNotice.title);
            formData.append("content", newNotice.content);
            formData.append("type", newNotice.type);
            formData.append("category", newNotice.category);

            const data = await addNotice({ data: formData });
            if (data.status > 299 || data.status < 200) {
                const message = data && data.message ? data.message : "Something went wrong";
                throw new Error(message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleDeleteNotice = async (category, id) => {
        try {
            const data = await deleteNotice({ id: id });

            if (data.status > 299 || data.status < 200) {
                const message = data && data.message ? data.message : "Something went wrong";
                throw new Error(message);
            }

            if (category === "app") {
                const filteredNotices = notices.app.filter((notice) => notice.idx !== id);
                setNotices({ ...notices, app: filteredNotices });
            } else if (category === "seller") {
                const filteredNotices = notices.seller.filter((notice) => notice.idx !== id);
                setNotices({ ...notices, seller: filteredNotices });
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchNotices();
    }, []);

    return (
        <div className="notice_page">
            <section className="page_header flex items-center gap-4">
                <Link to="/admin/setting/banner">
                    <Button color="black">배너관리</Button>
                </Link>
                <Link to="/admin/setting/popup">
                    <Button color="black">팝업관리</Button>
                </Link>
                <Link to="/admin/setting/notice">
                    <Button variant="contained" color="primary" hover="off">
                        공지 관리
                    </Button>
                </Link>
            </section>

            <hr className="border-gray-200 mt-4 mb-6" />

            <section>
                <div className="flex">
                    <div className="max-w-[200px] w-full mb-4 mr-4">
                        <Select
                            className="w-full"
                            name="category"
                            value={newNotice.category}
                            onChange={handleChangeData}
                        >
                            <option value="APP">자꾸다꾸 앱</option>
                            <option value="SELLER">판매자 페이지</option>
                        </Select>
                    </div>
                    <div className="max-w-[200px] w-full mb-4">
                        <Select className="w-full" name="type" value={newNotice.type} onChange={handleChangeData}>
                            <option value="NORMAL">일반</option>
                            <option value="SYSTEM">시스템</option>
                            <option value="MANUAL">메뉴얼</option>
                        </Select>
                    </div>
                </div>

                <div className="mb-4">
                    <h5 className="mb-2 pl-4">제목</h5>
                    <Input
                        variant="outlined"
                        color="gray"
                        placeholder="제목 입력"
                        className="px-4 mb-4"
                        name="title"
                        value={newNotice.title}
                        onChange={handleChangeData}
                    />
                    <TextArea
                        variant="outlined"
                        color="gray"
                        rows={5}
                        placeholder="내용 입력"
                        name="content"
                        value={newNotice.content}
                        onChange={handleChangeData}
                    />
                </div>

                <div className="f-center">
                    <Button variant="contained" color="primary" onClick={handleAddNotice}>
                        등록하기
                    </Button>
                </div>
            </section>

            <hr className="border-transparent my-4" />

            <section>
                <div className="mb-8">
                    <h5 className="mb-2 pl-4 font-medium">자꾸다꾸 앱</h5>

                    <div className="relative overflow-x-auto">
                        <table className="w-full text-sm text-center text-gray-500 border border-gray-300">
                            <thead className="text-gray-700 uppercase bg-gray-100">
                                <tr className="border-b border-b-gray-300">
                                    <th
                                        scope="col"
                                        className="px-6 py-3 border-r border-r-gray-300 last:border-gray-0 w-28"
                                    >
                                        삭제
                                    </th>
                                    <th scope="col" className="px-6 py-3 border-r border-r-gray-300 last:border-gray-0">
                                        제목
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 border-r border-r-gray-300 last:border-gray-0 w-32"
                                    >
                                        등록일
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {notices?.app.map((notice) => (
                                    <tr key={notice.idx} className="bg-white border-b">
                                        <th
                                            scope="row"
                                            className="px-6 py-2 font-medium text-primary whitespace-nowrap border-r border-r-gray-300 last:border-gray-0"
                                        >
                                            <Button
                                                size="xs"
                                                className="hover:bg-transparent"
                                                onClick={() => handleDeleteNotice("app", notice.idx)}
                                            >
                                                삭제
                                            </Button>
                                        </th>
                                        <td className="px-6 py-2 border-r border-r-gray-300 last:border-gray-0 text-left text-primary">
                                            {notice?.title || "판매자 공지"}
                                        </td>
                                        <td className="px-6 py-2 border-r border-r-gray-300 last:border-gray-0">
                                            {formatDate(notice.createdAt) || "-"}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="mb-8">
                    <h5 className="mb-2 pl-4 font-medium">판매자어드민</h5>

                    <div className="relative overflow-x-auto">
                        <table className="w-full text-sm text-center text-gray-500 border border-gray-300">
                            <thead className="text-gray-700 uppercase bg-gray-100">
                                <tr className="border-b border-b-gray-300">
                                    <th
                                        scope="col"
                                        className="px-6 py-3 border-r border-r-gray-300 last:border-gray-0 w-28"
                                    >
                                        삭제
                                    </th>
                                    <th scope="col" className="px-6 py-3 border-r border-r-gray-300 last:border-gray-0">
                                        제목
                                    </th>
                                    <th
                                        scope="col"
                                        className="px-6 py-3 border-r border-r-gray-300 last:border-gray-0 w-32"
                                    >
                                        등록일
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {notices?.seller.map((notice) => (
                                    <tr key={notice.idx} className="bg-white border-b">
                                        <th
                                            scope="row"
                                            className="px-6 py-2 font-medium text-primary whitespace-nowrap border-r border-r-gray-300 last:border-gray-0"
                                        >
                                            <Button
                                                size="xs"
                                                className="hover:bg-transparent"
                                                onClick={() => handleDeleteNotice("seller", notice.idx)}
                                            >
                                                삭제
                                            </Button>
                                        </th>
                                        <td className="px-6 py-2 border-r border-r-gray-300 last:border-gray-0 text-left text-primary">
                                            {notice?.title || "판매자 공지"}
                                        </td>
                                        <td className="px-6 py-2 border-r border-r-gray-300 last:border-gray-0">
                                            {formatDate(notice.createdAt) || "-"}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default NoticeManagement;
