import { useEffect, useState } from "react";
import { getProductsStatus, getSettlement, getUsersStatus } from "../../apis";

const Dashboard = () => {
    const [productCount, setProductCount] = useState({});
    const [userCount, setUserCount] = useState({});
    const [reqsCount, setReqsCount] = useState("");

    const fetchProductCount = async () => {
        try {
            const { data } = await getProductsStatus();

            if (data.status > 299 || data.status < 200) {
                const message = data && data.message ? data.message : "Something went wrong";
                throw new Error(message);
            }
            setProductCount(data);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchUserCount = async () => {
        try {
            const { data } = await getUsersStatus();

            if (data.status > 299 || data.status < 200) {
                const message = data && data.message ? data.message : "Something went wrong";
                throw new Error(message);
            }
            setUserCount(data);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchReqsCount = async () => {
        try {
            const { data } = await getSettlement({ status: "WAITING" });
            if (data.status > 299 || data.status < 200) {
                const message = data && data.message ? data.message : "Something went wrong";
                throw new Error(message);
            }
            setReqsCount(data.items.length);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchProductCount();
        fetchUserCount();
        fetchReqsCount();
    }, []);

    return (
        <div className="dashboard_page">
            <section className="info_section grid grid-cols-12 gap-8 items-stretch mt-8">
                <div className="w-full col-span-full lg:col-span-4">
                    <h5 className="flex items-center pl-3 mb-3 font-medium">상품현황</h5>

                    <div className="flex w-full gap-5">
                        <div className="w-1/3 rounded-3xl shadow-app1 px-4 py-8">
                            <p className="text-sm mb-10 font-medium">승인대기 수</p>
                            <p className="text-4xl text-primary font-bold f-center">
                                {productCount.waitingProductCount || "0"}{" "}
                                <span className="text-base text-gray font-normal ml-1">건</span>
                            </p>
                        </div>

                        <div className="w-2/3 rounded-3xl shadow-app1 px-4 py-8">
                            <p className="text-sm mb-10 font-medium">등록상품 수</p>
                            <p className="text-4xl text-primary font-bold f-center">
                                {productCount.approvedProductCount || "0"}{" "}
                                <span className="text-base text-gray font-normal ml-1">개</span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="w-full col-span-full lg:col-span-3">
                    <h5 className="flex items-center pl-3 mb-3 font-medium">고객현황</h5>

                    <div className="flex w-full gap-5">
                        <div className="w-full rounded-3xl shadow-app1 p-8">
                            <p className="text-sm mb-10 font-medium">입점 작가 수</p>
                            <p className="text-4xl text-primary font-bold f-center">
                                {userCount.totalDesignerCount || "0"}
                                <span className="text-base text-gray font-normal ml-1">명</span>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="w-full col-span-full mt-8 lg:mt-0 lg:col-span-2">
                    <div className="mb-3">
                        <h5 className="flex items-center pl-3 font-medium">정산현황</h5>
                    </div>

                    <div className="w-full rounded-3xl shadow-app1 p-8">
                        <p className="text-sm mb-10 font-medium">정산신청</p>
                        <p className="text-xl md:text-3xl xl:text-4xl 2xl:text-4xl text-primary font-bold f-center">
                            {reqsCount || "0"} <span className="text-base text-gray font-normal ml-1">건</span>
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Dashboard;
