import formatDate from "./formatDate";

export const formatCate = (id) => {
    if (id === "0") return "스티커";
    if (id === "1") return "다이어리";
};

export const formatSubcate = (id) => {
    if (id === "0") return "캐릭터 스티커";
    if (id === "1") return "메모지";
    if (id === "2") return "데코 스티커";
    if (id === "3") return "마스킹 테이프";
    if (id === "10") return "다이어리/플래너";
    if (id === "11") return "노트";
    if (id === "12") return "속지";
};

export const formatBusinessType = (id) => {
    if (id === "0") return "개인 프리랜서";
    if (id === "1") return "간이사업자";
    if (id === "2") return "일반사업자(개인/법인)";
};

export const formatUserState = (value) => {
    if (value === true) return "탈퇴";
    if (value === false) return "정상";
};

export const formatProductState = (id) => {
    if (id === "0") return "임시저장";
    if (id === "1") return "승인대기";
    if (id === "2") return "승인반려";
    if (id === "10") return "승인완료";
    if (id === "11") return "판매중지";
    if (id === "50") return "삭제";
};

export const formatPeriod = (period) => {
    const today = new Date();

    if (period === "today") {
        return {
            sdate: formatDate(today),
            edate: formatDate(today),
        };
    } else if (period === "week") {
        const oneWeekAgo = new Date(today);
        oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
        return {
            sdate: formatDate(oneWeekAgo),
            edate: formatDate(today),
        };
    } else if (period === "month") {
        const oneMonthAgo = new Date(today);
        oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
        return {
            sdate: formatDate(oneMonthAgo),
            edate: formatDate(today),
        };
    } else if (period === "tmonth") {
        const threeMonthsAgo = new Date(today);
        threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);
        return {
            sdate: formatDate(threeMonthsAgo),
            edate: formatDate(today),
        };
    }
};

export function formatDiscount(originalPrice, discountedPrice) {
    if (originalPrice <= 0 || discountedPrice <= 0) {
        return 0;
    }

    const discountAmount = originalPrice - discountedPrice;
    const discountRate = (discountAmount / originalPrice) * 100;

    return discountRate;
}

export const priceList = [
    { id: "1", price: "0" },
    { id: "2", price: "1500" },
    { id: "3", price: "3000" },
    { id: "4", price: "4400" },
    { id: "5", price: "6000" },
    { id: "6", price: "7000" },
    { id: "7", price: "8800" },
    { id: "8", price: "9900" },
    { id: "9", price: "12000" },
    { id: "10", price: "14000" },
    { id: "11", price: "15000" },
    { id: "12", price: "17000" },
    { id: "13", price: "19000" },
    { id: "14", price: "20000" },
    { id: "15", price: "22000" },
    { id: "16", price: "23000" },
    { id: "17", price: "25000" },
];

export const formatPrice = (id) => {
    if (id === "1") return "0";
    if (id === "2") return "1500";
    if (id === "3") return "3000";
    if (id === "4") return "4400";
    if (id === "5") return "6000";
    if (id === "6") return "7000";
    if (id === "7") return "8800";
    if (id === "8") return "9900";
    if (id === "9") return "12000";
    if (id === "10") return "14000";
    if (id === "11") return "15000";
    if (id === "12") return "17000";
    if (id === "13") return "19000";
    if (id === "14") return "20000";
    if (id === "15") return "22000";
    if (id === "16") return "23000";
    if (id === "17") return "25000";
};

export function formatCompanyNum(companyNum) {
    if (companyNum.length !== 10) {
        return companyNum;
    }

    const firstPart = companyNum.slice(0, 3);
    const secondPart = companyNum.slice(3, 5);
    const thirdPart = companyNum.slice(5);

    return `${firstPart}-${secondPart}-${thirdPart}`;
}

export function formatPricewithComma(price) {
    if (!price) return;

    if (typeof price === "number") {
        price = price.toString();
    }

    return price.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const bankList = [
    { id: 1, value: "국민은행" },
    { id: 2, value: "기업은행" },
    { id: 3, value: "농협은행" },
    { id: 4, value: "신한은행" },
    { id: 5, value: "하나은행" },
    { id: 6, value: "우리은행" },
    { id: 7, value: "산업은행" },
    { id: 8, value: "제일은행" },
    { id: 9, value: "카카오뱅크" },
    { id: 10, value: "토스뱅크" },
    { id: 11, value: "우체국" },
    { id: 12, value: "한국씨티은행" },
    { id: 13, value: "케이뱅크" },
    { id: 14, value: "지역농축협" },
    { id: 15, value: "새마을금고" },
    { id: 16, value: "수협은행" },
    { id: 17, value: "신협" },
    { id: 18, value: "저축은행" },
    { id: 19, value: "경남은행" },
    { id: 20, value: "광주은행" },
    { id: 21, value: "대구은행" },
    { id: 22, value: "부산은행" },
    { id: 23, value: "전북은행" },
    { id: 24, value: "제주은행" },
    { id: 25, value: "HSBC은행" },
    { id: 26, value: "도이치은행" },
    { id: 27, value: "JP모간체이스은행" },
    { id: 28, value: "BOA은행" },
    { id: 29, value: "BNP파리바은행" },
    { id: 30, value: "중국공상은행" },
    { id: 31, value: "산립조합" },
    { id: 32, value: "중국건설은행" },
];
