import { useMemo, useState } from "react";
import { AiOutlineCaretDown } from "react-icons/ai";

import Select from "../Select/Select";
import flags from "../../static/json/flags.json";
import classNames from "classnames";

const FlagPicker = ({ onChange, size = "md" }) => {
    const [flag, setFlag] = useState({ code: "KR", display: false });

    const [sizeClass, textClass, iconclass] = useMemo(() => {
        if (size === "xs") return ["px-2 py-0.5", "text-base", "text-sm"];
        if (size === "sm") return ["px-3 py-0.5", "text-2xl", "text-base"];
        if (size === "md") return ["px-4 py-1", "text-3xl", "text-lg"];
        if (size === "lg") return ["px-4 py-1.5", "text-4xl", "text-xl"];
    }, [size]);

    const handleChangeFlag = (e) => {
        setFlag({ code: e.target.value, display: false });
        if (onChange) onChange(e);
    };

    return (
        <div className="flag_picker">
            <div
                className={classNames("relative px-4 py-1 rounded-full border border-gray-300 f-center z-0", sizeClass)}
            >
                <Select
                    onChange={handleChangeFlag}
                    value={flag.code}
                    className={`absolute top-0 left-0 right-0 bottom-0 z-10 ${flag.display ? "" : "opacity-0"}`}
                >
                    {flags.map((flag) => (
                        <option key={flag.code} value={flag.code}>
                            {flag.emoji} - {flag.name}
                        </option>
                    ))}
                </Select>

                <AiOutlineCaretDown className={classNames("text-lg mr-2", iconclass)} />

                <span className={classNames("leading-3", textClass)}>
                    {flags.find((ele) => ele.code === flag.code)?.emoji}
                </span>
            </div>
        </div>
    );
};

export default FlagPicker;
