import React, { useEffect, useState } from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { FaRegCircle } from "react-icons/fa";

const RadioButton = ({ checked = false, color = "primary", size = "md", onClick, onChage }) => {
    const [active, setActive] = useState(checked || false);

    if (size === "xs") size = "text-lg";
    if (size === "sm") size = "text-xl";
    if (size === "md") size = "text-2xl";
    if (size === "lg") size = "text-3xl";
    if (size === "xl") size = "text-4xl";
    if (size === "2xl") size = "text-5xl";

    const colorVariant = {
        primary: `text-primary ${size}`,
        gray: `text-gray ${size}`,
        green: `text-green-600 ${size}`,
    };

    if (!color) throw new Error(`RadioButton color: ${color} is not valid`);
    if (!colorVariant[color]) throw new Error(`RadioButton color: ${color} is not valid`);

    useEffect(() => {
        if (active === checked) return;
        if (checked === undefined) return;
        setActive(checked);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checked]);

    const handleClckToggle = (e, bool) => {
        if (onChage) onChage(e, bool);
        if (onClick) onClick(e);
        setActive(bool);
    };

    if (active) {
        return (
            <BsCheckCircleFill
                className={`cursor-pointer ${colorVariant[color]}`}
                onClick={(e) => handleClckToggle(e, false)}
            />
        );
    }

    return (
        <FaRegCircle className={`cursor-pointer ${colorVariant[color]}`} onClick={(e) => handleClckToggle(e, true)} />
    );
};

export default RadioButton;
