import classnames from "classnames";
import React from "react";

const Select = ({ children, className, value, defaultValue, onChange, ...props }) => {
    return (
        <select
            className={classnames(
                `border border-gray-300 text-gray-600 text-sm rounded-full block py-1 px-3`,
                className
            )}
            value={value}
            defaultValue={defaultValue}
            onChange={onChange}
            {...props}
        >
            {children}
        </select>
    );
};

export default Select;
