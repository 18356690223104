// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slide-top {
    animation: slide-top 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-top {
    0% {
        transform: translateY(100vh);
    }

    100% {
        transform: translateY(0);
    }
}
`, "",{"version":3,"sources":["webpack://./src/static/css/animations.css"],"names":[],"mappings":"AAAA;IAEI,mEAAmE;AACvE;;AAcA;IACI;QAEI,4BAA4B;IAChC;;IAEA;QAEI,wBAAwB;IAC5B;AACJ","sourcesContent":[".slide-top {\n    -webkit-animation: slide-top 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;\n    animation: slide-top 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;\n}\n\n@-webkit-keyframes slide-top {\n    0% {\n        -webkit-transform: translateY(100vh);\n        transform: translateY(100vh);\n    }\n\n    100% {\n        -webkit-transform: translateY(0);\n        transform: translateY(0);\n    }\n}\n\n@keyframes slide-top {\n    0% {\n        -webkit-transform: translateY(100vh);\n        transform: translateY(100vh);\n    }\n\n    100% {\n        -webkit-transform: translateY(0);\n        transform: translateY(0);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
