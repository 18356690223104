import { useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import cookieHelper from "../../utils/cookieHelper";
import logo from "../../static/img/logo-small.png";

const Sidebar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [subMenu, setSubMenu] = useState({
        members: { name: "members", open: false },
        sales: { name: "sales", open: false },
        settlement: { name: "settlement", open: false },
    });

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });

        const url = location.pathname.split("/")[2];
        if (!url) return;
        if (!subMenu[url]) return;
        if (subMenu[url].open === true) return;
        setSubMenu({ ...subMenu, [url]: { ...subMenu[url], open: true } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const handleClickLogout = (e) => {
        cookieHelper.deleteCookie("admin_access_token");
        navigate("/auth/login");
    };

    return (
        <div className="w-full px-6 py-12 transition-all duration-300">
            <div className="f-center">
                <Link to="/admin/dashboard">
                    <img src={logo} alt="zaccu daccu" style={{ width: "12.422vw" }} />
                </Link>{" "}
            </div>
            <div className="w-full mt-8">
                <ul className="text-center">
                    <li className="overall_status">
                        <NavLink
                            to="/admin/dashboard"
                            className={({ isActive }) => (isActive ? "navlink active_navlink" : "navlink")}
                        >
                            전체현황
                        </NavLink>
                    </li>

                    <li className="product_management">
                        <NavLink
                            to="/admin/product/list"
                            className={({ isActive }) => (isActive ? "navlink active_navlink" : "navlink")}
                        >
                            상품관리
                        </NavLink>
                    </li>

                    <li className="author_management">
                        <NavLink
                            to="/admin/author/list"
                            className={({ isActive }) => (isActive ? "navlink active_navlink" : "navlink")}
                        >
                            작가관리
                        </NavLink>
                    </li>

                    <li className="member_management">
                        <NavLink
                            to="/admin/members/list"
                            className={({ isActive }) => (isActive ? "navlink active_navlink" : "navlink")}
                        >
                            회원관리
                        </NavLink>
                    </li>

                    <li className="settlement_management">
                        <NavLink
                            to="/admin/settlement/list"
                            className={({ isActive }) => (isActive ? "navlink active_navlink" : "navlink")}
                        >
                            정산관리
                        </NavLink>
                    </li>

                    <li className="system_management">
                        <NavLink
                            to="/admin/setting/banner"
                            className={({ isActive }) => (isActive ? "navlink active_navlink" : "navlink")}
                        >
                            시스템관리
                        </NavLink>
                    </li>
                </ul>

                <ul className="mt-36">
                    <li className="mb-4 dashboard">
                        <p
                            className="text-lg font-normal text-center text-white underline cursor-pointer underline-offset-4"
                            onClick={handleClickLogout}
                        >
                            로그아웃
                        </p>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Sidebar;
