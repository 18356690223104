import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Input } from "../../components";

const PopupManagement = () => {
    const dateNow = new Date();
    const today = dateNow.toISOString().slice(0, 10);
    const [showPopup, setShowPopup] = useState(false);
    const [bannerName, setBannerName] = useState("");
    const [url, setUrl] = useState("");
    const [startDate, setStartDate] = useState(today);
    const [endDate, setEndDate] = useState(today);
    const [selectedFile, setSelectedFile] = useState(null);
    const [popupsData, setPopupsData] = useState({});

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleSubmit = async () => {
        const formattedStartDate = new Date(startDate).toISOString().slice(0, 10);
        const formattedEndDate = new Date(endDate).toISOString().slice(0, 10);

        const formData = new FormData();
        formData.append("name", bannerName);
        formData.append("url", url);
        formData.append("start_date", formattedStartDate);
        formData.append("end_date", formattedEndDate);
        formData.append("file", selectedFile);
        
        try {
            const response = await fetch("https://zcdc-excel.teamcredit.kr/fa/admin/popups", {
                method: "POST",
                body: formData,
            });
            if (response.status === 200) {
                setShowPopup(true);
                console.log("성공");
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    useEffect(() => {
        const fetchPopupsData = async () => {
            try {
                const response = await fetch('https://zcdc-excel.teamcredit.kr/fa/admin/popups');
                const data = await response.json();
                setPopupsData(data);
                console.log('Popups data:', data);
    
                // Set initial values for form inputs
                setBannerName(data.name);
                setUrl(data.url);
                setStartDate(data.start_date || today);
                setEndDate(data.end_date || today);
            } catch (error) {
                console.error('Error fetching popups:', error);
            }
        };
    
        fetchPopupsData();
    }, [selectedFile]);
    

    return (
        <div className="banner_page">
            <section className="page_header flex items-center gap-4">
                <Link to="/admin/setting/banner">
                    <Button color="black">배너관리</Button>
                </Link>
                <Link to="/admin/setting/popup">
                    <Button variant="contained" color="primary" hover="off">
                        팝업관리
                    </Button>
                </Link>
                <Link to="/admin/setting/notice">
                    <Button color="black">공지 관리</Button>
                </Link>
            </section>

            <hr className="border-gray-200 mt-4 mb-10" />

            <section>
                <div className="ml-20 max-w-xs w-full h-96 bg-gray-300 mb-4 rounded-2xl">
                    {popupsData && <img src={popupsData.file_url} alt="Popup Image" className="w-full h-full object-cover" />}
                </div>
                <ul className="text-primary text-sm ml-20">
                    <li>- jpg파일형식</li>
                    <li>- 230 x 125 사이즈</li>
                </ul>

                <hr className="border-transparent mt-4 mb-10" />

                <div className="relative overflow-x-auto">
                    <table className="w-full text-sm text-left text-gray-500 border border-gray-200">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                            <tr className="border-b border-b-gray-200">
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-center border-r border-gray-200 last:border-r-0"
                                >
                                    배너이름
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-center border-r border-gray-200 last:border-r-0"
                                >
                                    URL
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-center border-r border-gray-200 last:border-r-0"
                                >
                                    시작일
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-center border-r border-gray-200 last:border-r-0"
                                >
                                    종료일
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-center border-r border-gray-200 last:border-r-0"
                                >
                                    파일첨부
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-3 text-center border-r border-gray-200 last:border-r-0"
                                >
                                    저장
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="bg-white border-b">
                                <td className="px-6 py-2 border-r border-gray-200 last:border-r-0">
                                    {popupsData && (
                                        <>
                                            <Input
                                                type="text"
                                                variant="outlined"
                                                color="black"
                                                value={bannerName}
                                                placeholder="배너이름을 입력해주세요"
                                                className="text-center"
                                            />
                                        </>
                                    )}
                                </td>
                                <td className="px-6 py-2 border-r border-gray-200 last:border-r-0">
                                    {popupsData && (
                                        <>
                                            <Input
                                                type="text"
                                                variant="outlined"
                                                color="black"
                                                value={url}
                                                defaultValue="링크할 url을 입력해주세요."
                                                className="text-center"
                                            />
                                        </>
                                    )}
                                </td>
                                <td className="px-6 py-2 border-r border-gray-200 last:border-r-0">
                                    {popupsData && (
                                        <>
                                            <Input
                                                type="date"
                                                variant="outlined"
                                                color="black"
                                                value={startDate}
                                                name="startData"
                                                className="text-center"
                                            />
                                        </>
                                    )}
                                </td>
                                <td className="px-6 py-2 border-r border-gray-200 last:border-r-0">
                                    {popupsData && (
                                        <>
                                            <Input
                                                type="date"
                                                variant="outlined"
                                                color="black"
                                                value={endDate}
                                                name="endData"
                                                className="text-center"
                                            />
                                        </>
                                    )}
                                </td>
                                <td className="px-6 py-2 border-r border-gray-200 last:border-r-0 items-center">
                                    <div className="flex gap-2 items-center">
                                        <input type="file" onChange={handleFileChange} />
                                        {selectedFile && <p className="px-2 py-1 text-gray-700">{selectedFile.name}</p>}
                                    </div>
                                </td>
                                <td className="px-6 py-2 border-r text-center border-gray-200 last:border-r-0">
                                    <Button variant="contained" size="sm" onClick={handleSubmit}>
                                        저장
                                    </Button>
                                </td>
                            </tr>
                        </tbody>



                    </table>
                </div>
                {showPopup && (
                <div className="popup">
                    <div className="popup-content">
                        <p>저장이 완료되었습니다.</p>
                        <Button onClick={() => setShowPopup(false)}>Close</Button>
                    </div>
                </div>
                )}
            </section>
        </div>
    );
};

export default PopupManagement;
