import React, { useState } from "react";
import { Button, Input, ModalBase, RadioButton, Select } from "../../../components";

import logo from "../../../static/img/logo_primary.png";
import imagePlaceholder from "../../../static/img/image_gallery.png";
import MobileVerificationModal from "./MobileVerificationModal";
import { FiEdit } from "react-icons/fi";
import { BsTrash } from "react-icons/bs";

const steps = {
    auth_details: "auth_details",
    personal_details: "personal_details",
    mobile_auth: "mobile_auth",
};

const Register = () => {
    const [step, setStep] = useState(steps.auth_details);
    const [profileImage, setProfileImage] = useState({ file: null, url: "" });

    const handleChangeProfilePhoto = (e) => {
        const { files } = e.target;
        if (files.length === 0) return;

        const url = URL.createObjectURL(files[0]);
        setProfileImage({ file: files[0], url });
    };

    return (
        <div className="register_page f-center min-h-screen overflow-auto w-full">
            <div className="max-w-xs w-full p-4">
                <div className="mb-10 f-center">
                    <img src={logo} alt="logo" width="280px" />
                </div>

                <div className="text-center mb-8">
                    <h2 className="text-primary font-medium text-xl mb-2">작가등록</h2>
                    <p className="text-2xl font-bold md:text-4xl">반가워요!</p>
                </div>

                {step === steps.auth_details && (
                    <>
                        <div className="inputs">
                            <div className="form_group mb-6">
                                <label htmlFor="r_email" className="mb-3 block font-semibold text-xl">
                                    이메일 주소
                                </label>
                                <Input id="r_email" type="email" variant="outlined" color="gray" placeholder="example@zaccudaccu.com" name="email" />
                            </div>

                            <div className="form_group">
                                <label htmlFor="r_pass" className="mb-3 block font-semibold text-xl">
                                    비밀번호
                                </label>
                                <Input id="r_pass" className="mb-2" type="password" variant="outlined" color="gray" placeholder="e비밀번호를 입력해주세요" name="password" />
                                <Input type="password" variant="outlined" color="gray" placeholder="비밀번호를 다시 입력해주세요" name="confirmPassword" />
                            </div>
                        </div>

                        <div className="action mt-6">
                            <Button className="w-full" variant="contained" color="primary" size="lg" onClick={(e) => setStep(steps.personal_details)}>
                                다 <span className="mx-2"></span> 음
                            </Button>
                        </div>
                    </>
                )}

                {step === steps.personal_details && (
                    <>
                        <div className="profile_photo">
                            <h4 className="font-medium text-lg mb-3">프로필 사진</h4>

                            <div className="relative w-32 h-32 object-cover bg-gray-200 rounded-full overflow-hidden f-center ml-20 cursor-pointer group">
                                <input type="file" className="absolute top-0 bottom-0 left-0 right-0 z-10 opacity-0 cursor-pointer" onChange={handleChangeProfilePhoto} />
                                {!profileImage.file && <img src={imagePlaceholder} alt="profile_photo" className="object-cover" width="28" height="28" />}
                                {profileImage.file && <img src={profileImage.url} alt="profile_photo" className="object-cover w-32 h-32" />}
                                {profileImage.file && (
                                    <div className="w-32 h-32 absolute top-0 bottom-0 left-0 right-0 f-center bg-[rgba(225,225,225,0.3)] z-20 hidden group-hover:flex">
                                        <Button variant="contained" color="primary" icon size="sm">
                                            <FiEdit />
                                        </Button>
                                        <Button variant="contained" color="primary" icon size="sm" className="ml-2">
                                            <BsTrash />
                                        </Button>
                                    </div>
                                )}
                            </div>

                            <ul className="mt-4 text-gray font-normal">
                                <li>
                                    · 권장 이미지 사이즈 <span className="text-primary">500x500px</span>
                                </li>
                                <li>
                                    · <span className="text-primary">jpg / jpeg / png</span> 파일형식
                                </li>
                            </ul>
                        </div>

                        <div className="inputs mt-8">
                            <div className="form_group mb-6">
                                <label htmlFor="r_name" className="mb-2 block font-semibold text-lg">
                                    작가 및 브랜드명(한글)
                                </label>
                                <Input id="r_name" variant="outlined" color="gray" placeholder="작가 및 브랜드명을 입력해주세요" name="name" />
                            </div>

                            <div className="form_group mb-6">
                                <label htmlFor="r_name" className="mb-2 block font-semibold text-lg">
                                    작가 및 브랜드명(영문)
                                </label>
                                <Input id="r_name" variant="outlined" color="gray" placeholder="작가 및 브랜드명을 입력해주세요" name="name" />
                            </div>

                            <div className="form_group mb-1">
                                <label htmlFor="r_brand" className="mb-2 block font-semibold text-lg">
                                    작가 및 브랜드 소개
                                </label>
                                <Input id="r_brand" variant="outlined" color="gray" placeholder="작가 및 브랜드를 소개해주세요" name="brand" />
                                <p className="text-end text-gray-300 text-sm mt-1 pr-1">0/1000</p>
                            </div>

                            <div className="form_group mb-6">
                                <label htmlFor="r_business" className="mb-2 block font-semibold text-lg">
                                    사업자 형태
                                </label>
                                <Select id="r_business" defaultValue="개인 작가(사업자 등록을 하지 않았어요)" name="business">
                                    <option value="개인 작가(사업자 등록을 하지 않았어요)">개인 작가(사업자 등록을 하지 않았어요)</option>
                                    <option value="개인 작가(사업자 등록을 하지 않았어요)">개인 작가(사업자 등록을 하지 않았어요)</option>
                                    <option value="개인 작가(사업자 등록을 하지 않았어요)">개인 작가(사업자 등록을 하지 않았어요)</option>
                                    <option value="개인 작가(사업자 등록을 하지 않았어요)">개인 작가(사업자 등록을 하지 않았어요)</option>
                                </Select>
                            </div>

                            <hr className="border-gray-200 my-5" />

                            <div className="form_group">
                                <label htmlFor="r_checklist" className="mb-3 block font-semibold text-lg">
                                    필수 확인사항
                                </label>
                                <div className="radio_group text-primary">
                                    <div className="flex mb-2">
                                        <RadioButton />
                                        <p className="ml-2">
                                            개인정보 처리 방침 <span className="text-black">동의</span> (필수)
                                        </p>
                                    </div>

                                    <div className="flex mb-2">
                                        <RadioButton />
                                        <p className="ml-2">
                                            이용약관 <span className="text-black">동의</span> (필수)
                                        </p>
                                    </div>

                                    <div className="flex">
                                        <RadioButton />
                                        <p className="ml-2">
                                            입점약관 <span className="text-black">동의</span> (필수)
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="action mt-12 mb-5">
                            <Button className="w-full" variant="contained" color="primary" size="lg" onClick={(e) => setStep(steps.mobile_auth)}>
                                휴대폰 인증하기
                            </Button>
                        </div>
                    </>
                )}
            </div>

            {step === steps.mobile_auth && (
                <ModalBase>
                    <MobileVerificationModal onClose={(e) => setStep(steps.personal_details)} />
                </ModalBase>
            )}
        </div>
    );
};

export default Register;
