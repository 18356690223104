import classnames from "classnames";

const TextArea = ({
    variant = "default",
    color = "primary",
    placeholder,
    name,
    rows,
    cols,
    maxLength,
    required,
    onChange,
    className,
    ...props
}) => {
    const colorVariants = {
        default: {
            primary: `text-gray-900`,
            white: `text-white`,
            gray: `text-gray`,
        },
        outlined: {
            primary: `border border-primary text-primary`,
            white: `border border-white text-white`,
            gray: `border text-gray-400 border-gray-300`,
        },
    };

    if (!colorVariants[variant] === undefined) throw new Error(`No variant found for: [${variant}]`);
    if (!colorVariants[variant][color] === undefined)
        throw new Error(`No color found for variant: [${variant}, ${color}]`);

    return (
        <textarea
            name={name}
            rows={rows}
            cols={cols}
            maxLength={maxLength}
            className={classnames(
                `block w-full p-4 text-sm rounded-2xl focus:ring-0 focus:outline-0 ${colorVariants[variant][color]}`,
                className
            )}
            placeholder={placeholder}
            required={required}
            onChange={onChange}
            {...props}
        />
    );
};

export default TextArea;
