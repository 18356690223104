import React from "react";

const Header = () => {
    return (
        <header className="header bg-black p-4 flex items-center">
            <div className="w-full md:w-1/5 max-w-xs">
                <h2 className="text-white text-3xl font-extrabold">Poob</h2>
            </div>
            <div className="w-full md:w-3/4">
                <p className="text-white text-xl font-bold">관리자 페이지</p>
            </div>
        </header>
    );
};

export default Header;
