// Router Outlet of Admin
export { default as AdminOutlet } from "./AdminOutlet/AdminOutlet";

// Home Page
export { default as Home } from "./Home/Home";

// ---------------- Admin Pages ----------------------
// Overall Status
export { default as DashboardAdmin } from "./Dashboard/DashboardAdmin";

//-- Product Management
export { default as ProductManagement } from "./Product/ProductManagement";
export { default as UpdateProduct } from "./Product/UpdateProduct";

//-- Author Management
export { default as AuthorList } from "./Author/AuthorList";
export { default as AuthorInformation } from "./Author/AuthorInformation";

//-- Settlement Management
export { default as SettlementMenegement } from "./Settlement/SettlementMenegement";
export { default as SettlementDetails } from "./Settlement/SettlementDetails";

//-- Member Management
export { default as MemberList } from "./Members/MemberList";
export { default as MemberInfo } from "./Members/MemberInfo";
export { default as MemberSuspension } from "./Members/MemberSuspension";

//-- System Management
export { default as BannerManagement } from "./SystemManagement/BannerManagement";
export { default as NoticeManagement } from "./SystemManagement/NoticeManagement";
export { default as PopupManagement } from "./SystemManagement/PopupManagement";

//-- Auth Pages
export { default as Auth } from "./Auth/Auth";
export { default as Login } from "./Auth/Login/Login";
export { default as Register } from "./Auth/Register/Register";
