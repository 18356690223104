import { Button } from "../../components";
import excelIcon from "../../static/img/excel.png";

const data = [
    {
        id: 1,
        productName: "내가 좋아해!",
        authorNameKorean: "몽실먼지",
        regPrice: "23,000",
        price: "20,000",
        pDate: "2021.12.31  22:30:14",
        pMethod: "애플",
        situation: "오류",
    },
    {
        id: 2112,
        productName: "내가 좋아해!",
        authorNameKorean: "몽실먼지",
        regPrice: "23,000",
        price: "20,000",
        pDate: "2021.12.31  22:30:14",
        pMethod: "애플",
        situation: "오류",
    },
    ...Array.from({ length: 13 }, () => ({})),
];

const MemberSuspension = () => {
    return (
        <div className="profile_page">
            <div className="heading">
                <h2 className="text-xl font-medium">회원관리</h2>
            </div>

            <hr className="border-gray-200 my-6" />

            <section className="filters_container">
                <div className="filters mb-8">
                    <div className="flex items-center border-t border-gray-300">
                        <div className="w-1/2 flex items-center">
                            <div className="w-36 text-center text-white font-medium f-center p-3 bg-primary mr-5">가입일</div>

                            <p className="flex items-center gap-8 text-lg">2023-06-06</p>
                        </div>

                        <div className="w-1/2 flex items-center">
                            <div className="w-36 text-center text-white font-medium f-center p-3 bg-primary mr-5">상 태</div>

                            <div className="flex items-center justify-between">
                                <p className="flex items-center gap-8 text-lg mr-20">정상</p>
                                <Button variant="outlined" color="primary" size="sm" className="mr-1">
								정지사유
                                </Button>
                                <Button variant="contained" color="primary" size="sm">
                                    이용정지
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center border-t border-gray-300">
                        <div className="w-1/2 flex items-center">
                            <div className="w-36 text-center text-white font-medium f-center p-3 bg-primary mr-5">성 명</div>

                            <p className="flex items-center gap-8 text-lg">주지은</p>
                        </div>

                        <div className="w-1/2 flex items-center">
                            <div className="w-36 text-center text-white font-medium f-center p-3 bg-primary mr-5">성 별</div>

                            <p className="flex items-center gap-8 text-lg">여자</p>
                        </div>
                    </div>

                    <div className="flex items-center border-t border-gray-300">
                        <div className="w-1/2 flex items-center">
                            <div className="w-36 text-center text-white font-medium f-center p-3 bg-primary mr-5">휴대폰번호</div>

                            <p className="flex items-center gap-8 text-lg">010-0000-0000</p>
                        </div>

                        <div className="w-1/2 flex items-center">
                            <div className="w-36 text-center text-white font-medium f-center p-3 bg-primary mr-5">생년월일</div>

                            <p className="flex items-center gap-8 text-lg">1995년 8월 8일</p>
                        </div>
                    </div>

                    <div className="flex items-center border-t border-b border-gray-300">
                        <div className="w-1/2 flex items-center">
                            <div className="w-36 text-center text-white font-medium f-center p-3 bg-primary mr-5">이메일</div>

                            <p className="flex items-center gap-8 text-lg">jennychoo.korea@gmail.com</p>
                        </div>

                        <div className="w-1/2 flex items-center">
                            <div className="w-36 text-center text-white font-medium f-center p-3 bg-primary mr-5">가입유형</div>

                            <p className="flex items-center gap-8 text-lg">구글</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="sales_records mt-20">
                <div className="flex items-center mb-3">
                    <h5 className="text-gray">상품개수 : 0건</h5>
                    <div className="ml-auto">
                        <Button variant="outlined" color="green" size="sm" className="!rounded-full f-center">
                            <img src={excelIcon} alt="excel" height="19px" width="19px" className="object-contain mr-2" />
                            엑셀로 내려받기
                        </Button>
                    </div>
                </div>

                <div className="relative overflow-x-auto">
                    <table className="w-full text-sm text-left text-black border border-gray-300 whitespace-nowrap">
                        <thead className="text-base bg-gray-100 border-b border-b-gray-300">
                            <tr>
                                <th scope="col" className="px-6 py-4 font-medium border-r border-r-gray-300">
                                    No
                                </th>
                                <th scope="col" className="px-6 py-4 font-medium border-r border-r-gray-300">
                                    상품명
                                </th>
                                <th scope="col" className="px-6 py-4 font-medium border-r border-r-gray-300">
                                    작가명
                                </th>
                                <th scope="col" className="px-6 py-4 font-medium border-r border-r-gray-300">
                                    정상가
                                </th>
                                <th scope="col" className="px-6 py-4 font-medium border-r border-r-gray-300">
                                    판매가
                                </th>
                                <th scope="col" className="px-6 py-4 font-medium border-r border-r-gray-300">
                                    결제일
                                </th>
                                <th scope="col" className="px-6 py-4 font-medium border-r border-r-gray-300">
                                    결제방법
                                </th>
                                <th scope="col" className="px-6 py-4 font-medium">
                                    상태
                                </th>
                            </tr>
                        </thead>
                        <tbody className="font-normal">
                            {data.map((product, i) => (
                                <tr key={i} className="bg-white border-b border-gray-300 last:border-b-0">
                                    <td className="px-6 py-3 border-r border-r-gray-300 whitespace-nowrap">{product.id}</td>
                                    <td className="px-6 py-3 border-r border-r-gray-300">{product.productName}</td>
                                    <td className="px-6 py-3 border-r border-r-gray-300">{product.authorNameKorean}</td>
                                    <td className="px-6 py-3 border-r border-r-gray-300">{product.regPrice}</td>
                                    <td className="px-6 py-3 border-r border-r-gray-300">{product.price}</td>
                                    <td className="px-6 py-3 border-r border-r-gray-300">{product.pDate}</td>
                                    <td className="px-6 py-3 border-r border-r-gray-300">{product.pMethod}</td>
                                    <td className="px-6 py-3">{product.situation}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
    );
};

export default MemberSuspension;
