import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";

import config from "../../config/config";
import { formatPricewithComma } from "../../utils/formatData";
import { getSettlement } from "../../apis";
import formatDate from "../../utils/formatDate";
import { Button, Input, Pagination, RadioButton } from "../../components";

import excelIcon from "../../static/img/excel.png";

const SettlementMenegement = () => {
    const [settlement, setSettlement] = useState([]);
    const [isSearch, setIsSearch] = useState(false);
    const [excelDownload, setExcelDownload] = useState(false);
    const [page, setPage] = useState({
        currentPage: 1,
        pageSize: 15,
        totalCount: 0,
        loading: false,
    });

    const calculateWithholdingTax = (paid_price, comm, vat) => {
        const baseAmount = parseFloat(paid_price) - parseFloat(comm) - parseFloat(vat);
        const tax = baseAmount * 0.033;
        return Math.floor(tax / 10) * 10;  // Floor to nearest 10
    }

    const [searchValue, setSearchValue] = useState({
        status: "ALL",
        sw: null,
        start: null,
        end: null,
    });
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setSearchValue(prevState => ({ ...prevState, [name]: value }));
    };

    const handleChangeStatus = (value) => {
        setSearchValue({ ...searchValue, status: value });
    };

    const handleChangeData = (e) => {
        let { name, value } = e.target;
        setSearchValue({ ...searchValue, [name]: value });
    };

    const handlePageChange = (value) => {
        if (value === page.currentPage) return;
        setPage({ ...page, currentPage: value });
    };

    const handleSearch = (e) => {
        e.preventDefault();
        setIsSearch(true);
        setPage({ ...page, currentPage: 1 });
        const { status, start, end, sw } = searchValue;

        fetchSettlement({
            status,
            start,
            end,
            sw,
            page: 1,
        });
    };

    const handleClickDownloadExcelFile = (e) => {
        setExcelDownload(true);
        try {
            window.open(
                `${config.apiUrl}/admins/reqs/excel?page=${page.currentPage}&perpage=${page.pageSize}`,
                "_blank"
            );

            setExcelDownload(false);
        } catch (err) {
            setExcelDownload(false);
        }
    };

    const fetchSettlement = async () => {
        try {
            setPage(prev => ({ ...prev, loading: true }));
            const params = { ...searchValue, page: page.currentPage, pageSize: page.pageSize };
            const { data } = await getSettlement(params);
            // Corrected from setSettlements to setSettlement
            setSettlement(data.items); // This should match your useState variable name
            setPage(prev => ({
                ...prev,
                totalCount: data.totalCount,
                loading: false,
            }));
        } catch (error) {
            console.error(error);
            toast.error("Failed to fetch settlements");
            setPage(prev => ({ ...prev, loading: false }));
        }
    };
    

    useEffect(() => {
        if (!isSearch) {
            fetchSettlement({
                page: page.currentPage,
                per_page: page.pageSize,
            });
        } else {
            const { status, start, end, sw, searchType } = searchValue;
            fetchSettlement({
                status,
                start,
                end,
                sw,
                searchType,
                page: page.currentPage,
            });
        }
    }, [page.currentPage, isSearch]);

    return (
        <div className="settlement_management_page">
            <div className="heading">
                <h2 className="text-xl font-medium">정산관리</h2>
            </div>

            <hr className="my-6 border-gray-200" />

            <section className="filters_container">
                <div className="mb-8 filters">
                    <div className="flex items-center border-t border-gray-300">
                        <div className="p-3 mr-5 font-medium text-center text-white w-36 f-center bg-primary">
                            상 <span className="block mx-3"></span> 태
                        </div>

                        <div className="flex items-center gap-8 text-lg">
                            <div className="flex items-center gap-2">
                                <RadioButton
                                    checked={searchValue.status === "ALL"}
                                    onClick={() => handleChangeStatus("ALL")}
                                />
                                <p>전체</p>
                            </div>

                            <div className="flex items-center gap-2">
                                <RadioButton
                                    checked={searchValue.status === "WAITING"}
                                    onClick={() => handleChangeStatus("WAITING")}
                                />
                                <p>정산대기</p>
                            </div>

                            <div className="flex items-center gap-2">
                                <RadioButton
                                    checked={searchValue.status === "COMPLETE"}
                                    onClick={() => handleChangeStatus("COMPLETE")}
                                />
                                <p>정산완료</p>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center border-t-2 border-gray-300">
                        <div className="p-3 mr-5 font-medium text-center text-white w-36 bg-primary">기간검색</div>

                        <div className="flex items-center gap-8 text-lg">
                            <Input
                                type="date"
                                variant="outlined"
                                color="gray"
                                name="start"
                                value={searchValue.start || ""}
                                onChange={handleChangeData}
                            />
                            <span>~</span>
                            <Input
                                type="date"
                                variant="outlined"
                                color="gray"
                                name="end"
                                value={searchValue.end || ""}
                                onChange={handleChangeData}
                            />
                        </div>
                    </div>

                    <div className="flex items-center border-t-2 border-b-2 border-gray-300">
                        <div className="p-3 mr-5 font-medium text-center text-white w-36 bg-primary f-center">
                            검 <span className="block mx-3"></span> 색
                        </div>

                        <div className="flex items-center w-full max-w-md gap-2 text-lg">
                            <div className="w-full max-w-sm">
                                <Input
                                    variant="outlined"
                                    color="gray"
                                    placeholder="작가명을 입력해주세요"
                                    name="sw"
                                    value={searchValue.sw || ""}
                                    onChange={handleChangeData}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="action f-center">
                    <Button variant="contained" color="primary" size="lgs" onClick={handleSearch}>
                        검 <span className="mx-2"></span> 색
                    </Button>
                </div>
            </section>

            <section className="mt-10 sales_records">
                <div className="flex items-center mb-3">
                    <h5 className="text-gray">검색개수 : {settlement.length} 건</h5>
                    <div className="ml-auto">
                        <Button
                            variant="outlined"
                            color="green"
                            size="sm"
                            className="!rounded-full f-center"
                            disabled={excelDownload}
                            onClick={handleClickDownloadExcelFile}
                        >
                            {" "}
                            <img
                                src={excelIcon}
                                alt="excel"
                                height="19px"
                                width="19px"
                                className="object-contain mr-2"
                            />
                            엑셀로 내려받기
                        </Button>
                    </div>
                </div>

                <div className="relative overflow-x-auto">
                    <table className="w-full text-sm text-left text-black border border-gray-300 whitespace-nowrap">
                        <thead className="text-base bg-gray-100 border-b border-b-gray-300">
                            <tr>
                                <th
                                    scope="col"
                                    className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                                >
                                    작가명
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                                >
                                    타입
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                                >
                                    정산완료일
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                                >
                                    정산신청일
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                                >
                                    판매합계금액
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                                >
                                    수수료
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                                >
                                    부가세
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                                >
                                    원천세
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                                >
                                    정산금액
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                                >
                                    정산계좌
                                </th>
                            </tr>
                        </thead>
                        <tbody className="font-normal">
                            {settlement.map((req) => (
                                <tr key={req.id} className="bg-white border-b border-gray-300 last:border-b-0">
                                    <td className="px-6 py-3 border-r border-r-gray-300 whitespace-nowrap">
                                        {req.brand_name || "-"}
                                    </td>
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300">
                                        {req.business_type === "0" && "개인 프리랜서"}
                                        {req.business_type === "1" && "간이사업자"}
                                        {req.business_type === "2" && "일반사업자(개인/법인)"}
                                    </td>
                                    <td className="px-6 py-3 text-center underline border-r border-r-gray-300 text-primary">
                                        {req.sent_at !== "" && req.sent_at !== undefined && req.sent_at !== null ? (
                                            <Link
                                                to={`/admin/settlement/list/${req.id}?type=${
                                                    req.business_type === "0" ? "individual" : "business"
                                                }&bs_users_id=${req.bs_users_id}`}
                                            >
                                                {formatDate(req.sent_at)}
                                            </Link>
                                        ) : (
                                            <Link
                                                to={`/admin/settlement/list/${req.id}?type=${
                                                    req.business_type === "0" ? "individual" : "business"
                                                }&bs_users_id=${req.bs_users_id}`}
                                            >
                                                정산하기
                                            </Link>
                                        )}
                                    </td>
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300">
                                        {formatDate(req.created_at) || "-"}
                                    </td>
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300">
                                        {formatPricewithComma(req.paid_price) || "-"}원
                                    </td>
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300">
                                        {formatPricewithComma(req.comm) || "-"}원
                                    </td>
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300">
                                        {formatPricewithComma(req.vat) || "-"}원
                                    </td>
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300">
                                    {req.paid_price && req.comm && req.vat ? 
                                        (req.business_type === "1" ? "0원" : formatPricewithComma(calculateWithholdingTax(req.paid_price, req.comm, req.vat)) + "원") 
                                        : "-"}
                                    </td>
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300 text-primary">
                                        {formatPricewithComma(req.mvat) || "-"}원
                                    </td>
                                    {/* // TODO: 계좌 데이터 수정되면 변경 */}
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300">
                                        {req.memo || "-"}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </section>

            <section className="mt-5 pagination f-center">
                <Pagination
                    pageSize={page.pageSize}
                    onPageChange={handlePageChange}
                    totalCount={page.totalCount}
                    currentPage={page.currentPage}
                />
            </section>
        </div>
    );
};

export default SettlementMenegement;
