import { useEffect, useState } from "react";
import { BsTrash } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";

import { Button, FlagPicker, Input, Select, TextArea } from "../../components";
import excelIcon from "../../static/img/excel.png";
import {
    blockUser,
    getBusinessFile,
    getDesigner,
    getProfileImage,
    updateBusiness,
    updateDesigner,
    updateProfileImage,
} from "../../apis";
import { Link, useParams } from "react-router-dom";
import formatDate from "../../utils/formatDate";
import { bankList, formatCate, formatPrice, formatSubcate } from "../../utils/formatData";
import { toast } from "react-hot-toast";
import formatDateSafari from "../../utils/formatForSafari";

const AuthorInformation = () => {
    // eslint-disable-next-line no-unused-vars
    // const [profileImage, setProfileImage] = useState({ file: null, url: "" });
    const [profileImage, setProfileImage] = useState("");
    const [newProfileImage, setNewProfileImage] = useState(new File([], ""));
    const { id } = useParams();
    const [certFile, setCertFile] = useState(null);
    const [bankFile, setBankFile] = useState(null);
    const [designerInfo, setDesignerInfo] = useState({
        profile: {},
        products: [],
    });
    const [isRetireUser, setIsRetireUser] = useState(false);
    const [personalNum, setPersonalNum] = useState({
        first: "",
        second: "",
    });

    const fetchDesigner = async () => {
        try {
            const { data } = await getDesigner({ id: id });

            if (data.status > 299 || data.status < 200) {
                const message = data && data.message ? data.message : "Something went wrong";
                throw new Error(message);
            }
            setDesignerInfo({
                profile: data.profile,
                products: data.products,
            });

            if (data?.profile?.personal_num !== "") {
                setPersonalNum({
                    first: data?.profile?.personal_num?.slice(0, 6),
                    second: data?.profile?.personal_num?.slice(6),
                });
            }

            if (data.profile.thumb_src && data.profile.thumb_src.length !== 0) {
                fetchProfileImage(data && data.profile.thumb_src);
            }

            if (data?.profile?.is_deleted === true) {
                setIsRetireUser(true);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const fetchProfileImage = async (src) => {
        try {
            const data = await getProfileImage({ id: src });
            if (data.status > 299 || data.status < 200) {
                const message = data && data.message ? data.message : "Something went wrong";
                throw new Error(message);
            }
            const blob = new Blob([data.data], {
                type: data.headers["content-type"],
            });

            setProfileImage(window.URL.createObjectURL(blob));
        } catch (Err) {
            console.log(Err);
        }
    };

    const handleChangePersonalNum = (e) => {
        const { name, value } = e.target;
        setPersonalNum({ ...personalNum, [name]: value });
    };

    const handleChangeData = (e) => {
        const { name, value } = e.target;
        setDesignerInfo({ ...designerInfo, profile: { ...designerInfo.profile, [name]: value } });
    };

    const handleEditProfile = async () => {
        try {
            const formData = new FormData();
            formData.append("id", designerInfo?.profile?.bs_users_id);
            formData.append("name", designerInfo?.profile?.name);
            formData.append("brand_name", designerInfo?.profile?.brand_name);
            formData.append("brand_name_en", designerInfo?.profile?.brand_name_en);
            formData.append("email", designerInfo?.profile?.email);
            formData.append("phone", designerInfo?.profile?.phone);
            formData.append("web_addr", designerInfo?.profile?.web_addr);
            formData.append("bio", designerInfo?.profile?.bio);
            const data = await updateDesigner({ data: formData });
            if (data.status > 299 || data.status < 200) {
                const message = data && data.message ? data.message : "Something went wrong";
                throw new Error(message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleEditSettlement = async () => {
        try {
            const formData = new FormData();
            formData.append("userId", designerInfo?.profile?.bs_users_id);
            formData.append("personal_num", personalNum.first + personalNum.second);
            formData.append("bank_holder", designerInfo?.profile?.bank_holder);
            formData.append("bank_name", designerInfo?.profile?.bank_name);
            formData.append("bank_num", designerInfo?.profile?.bank_num);
            formData.append("company_name", designerInfo?.profile?.company_name);
            formData.append("company_number", designerInfo?.profile?.company_number);
            formData.append("business_name0", designerInfo?.profile?.name);
            formData.append("business_name1", designerInfo?.profile?.business_name);
            formData.append("business_type", designerInfo?.profile?.business_type);
            formData.append("earnType", designerInfo?.profile?.earn_type);
            if (bankFile) {
                formData.append("bankFile", bankFile);
            }
            if (certFile) {
                formData.append("certFile", certFile);
            }

            const data = await updateBusiness({ data: formData });

            if (data.status > 299 || data.status < 200) {
                const message = data && data.message ? data.message : "Something went wrong";
                throw new Error(message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleUpdateProfileImage = async () => {
        try {
            const formData = new FormData();
            formData.append("userId", designerInfo.profile?.bs_users_id);
            formData.append("imgdata", newProfileImage);
            const data = updateProfileImage({ data: formData });

            if (data.status > 299 || data.status < 200) {
                const message = data && data.message ? data.message : "Something went wrong";
                throw new Error(message);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const onSubmitEdit = async () => {
        try {
            await handleEditProfile();
            await handleEditSettlement();
            if (newProfileImage && newProfileImage.size > 0) {
                await handleUpdateProfileImage();
            }
            toast.success("수정이 완료되었습니다");
        } catch (err) {}
    };

    const handleBlockUser = async () => {
        try {
            const data = await blockUser({ id: designerInfo?.profile?.bs_users_id });
            if (data.status > 299 || data.status < 200) {
                const message = data && data.message ? data.message : "Something went wrong";
                throw new Error(message);
            }
            toast.success("정지 완료되었습니다");
            fetchDesigner();
        } catch (err) {
            console.log(err);
        }
    };

    // 새 창으로 여는 플로우

    const handleDownloadFile = async (type) => {
        try {
            const { data } = await getBusinessFile({
                uid: designerInfo.profile.bs_users_id,
                ft: type,
            });

            if (data instanceof ArrayBuffer) {
                console.log("This is an ArrayBuffer");
            }

            const blob = new Blob([data], { type: "application/pdf" });
            const url = window.URL.createObjectURL(blob);

            // 새 창 열기
            const newWindow = window.open();
            newWindow.location = url;

            // 메모리 해제
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
            }, 100); // 지연시간은 적절히 조절
        } catch (err) {
            console.log(err);
            toast.error(err.response.data.error_string);
        }
    };

    // 다운로드로 여는 플로우

    // const handleDownloadFile = async (type) => {
    //     try {
    //         const { data } = await getBusinessFile({
    //             uid: designerInfo.profile.bs_users_id,
    //             ft: type,
    //         });

    //         if (data instanceof ArrayBuffer) {
    //             console.log("This is an ArrayBuffer");
    //         }

    //         const blob = new Blob([data], { type: "application/pdf" }); // 타입을 PDF로 명시적으로 설정
    //         const url = window.URL.createObjectURL(blob);
    //         const a = document.createElement("a");
    //         a.href = url;
    //         a.download = "filename.pdf";
    //         document.body.appendChild(a);
    //         a.click();
    //         document.body.removeChild(a);
    //         window.URL.revokeObjectURL(url); // Blob URL 해제
    //     } catch (err) {
    //         console.log(err);
    //         toast.error(err.response.data.error_string);
    //     }
    // };

    const handleChangeBankFile = (e) => {
        const file = e.target.files[0];
        setBankFile(file);
    };

    const handleChangeCertFile = (e) => {
        const file = e.target.files[0];
        setCertFile(file);
    };

    const openProfileImageInput = () => {
        document.getElementById("profileImage").click();
    };

    const handleChangeProfileImage = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setNewProfileImage(selectedFile);
        }
    };

    useEffect(() => {
        fetchDesigner();
    }, []);

    return (
        <div className="author_info_page">
            {/* <canvas id="pdfCanvas"></canvas> */}
            <div className="heading">
                <h2 className="text-xl font-medium">작가정보</h2>
            </div>

            <hr className="my-6 border-gray-200" />

            <section className="details">
                <div className="mb-6">
                    <div className="flex items-center border-t border-gray-300">
                        <div className="flex items-center w-1/2">
                            <div className="p-3 mr-5 font-medium text-center text-white w-36 f-center bg-primary">
                                입점일
                            </div>

                            <p className="flex items-center gap-8 text-lg">
                                {formatDate(designerInfo?.profile?.created_at)}
                            </p>
                        </div>

                        <div className="flex items-center w-1/2">
                            <div className="p-3 mr-5 font-medium text-center text-white w-36 f-center bg-primary">
                                상 태
                            </div>

                            <div className="flex items-center justify-between">
                                <p className="flex items-center gap-8 mr-20 text-lg">
                                    {designerInfo.profile.is_blocked
                                        ? "정지"
                                        : designerInfo.profile.is_deleted === true
                                        ? "탈퇴"
                                        : "정상"}
                                </p>
                                <Button variant="contained" color="primary" size="sm" onClick={handleBlockUser}>
                                    이용정지
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center border-t border-gray-300">
                        <div className="flex items-center w-1/2">
                            <div className="p-3 mr-5 font-medium text-center text-white w-36 f-center bg-primary">
                                작가명(한글)
                            </div>

                            <div className="max-w-xs">
                                <Input
                                    value={designerInfo.profile?.brand_name}
                                    variant="outlined"
                                    color="gray"
                                    name="brand_name"
                                    onChange={handleChangeData}
                                    disabled={isRetireUser}
                                    className="!text-font-input"
                                />
                            </div>
                        </div>

                        <div className="flex items-center w-1/2">
                            <div className="p-3 mr-5 font-medium text-center text-white w-36 f-center bg-primary">
                                작가명(영문)
                            </div>

                            <div className="max-w-xs">
                                <Input
                                    value={designerInfo.profile?.brand_name_en}
                                    variant="outlined"
                                    color="gray"
                                    name="brand_name_en"
                                    onChange={handleChangeData}
                                    disabled={isRetireUser}
                                    className="!text-font-input"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center border-t border-gray-300">
                        <div className="flex items-center w-1/2">
                            <div className="p-3 mr-5 font-medium text-center text-white w-36 f-center bg-primary">
                                휴대폰번호
                            </div>

                            <div className="flex items-center max-w-sm gap-2">
                                <FlagPicker size="sm" />
                                <Input
                                    value={designerInfo.profile?.phone}
                                    variant="outlined"
                                    color="gray"
                                    name="phone"
                                    onChange={handleChangeData}
                                    disabled={isRetireUser}
                                    className="!text-font-input"
                                />
                            </div>
                        </div>

                        <div className="flex items-center w-1/2">
                            <div className="p-3 mr-5 font-medium text-center text-white w-36 f-center bg-primary">
                                이메일
                            </div>

                            <div className="max-w-xs">
                                <Input
                                    value={designerInfo.profile?.email}
                                    type="email"
                                    variant="outlined"
                                    color="gray"
                                    name="email"
                                    onChange={handleChangeData}
                                    disabled={isRetireUser}
                                    className="!text-font-input"
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flex items-stretch border-t border-b border-gray-300">
                        <div className="p-3 mr-5 font-medium text-center text-white w-36 f-center bg-primary">
                            프로필 사진
                        </div>

                        <div className="p-2">
                            <div className="relative group">
                                {" "}
                                <div className="w-32 h-32 overflow-hidden bg-gray-200 rounded-full">
                                    {" "}
                                    <img
                                        src={
                                            newProfileImage && newProfileImage.size > 0
                                                ? URL.createObjectURL(newProfileImage)
                                                : profileImage
                                        }
                                        alt="profile_photo"
                                        className="object-cover"
                                    />
                                </div>
                                <div className="w-32 h-32 absolute rounded-full top-0 bottom-0 left-0 right-0 f-center bg-[rgba(225,225,225,0.3)] z-20 hidden group-hover:flex">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        icon
                                        size="sm"
                                        onClick={openProfileImageInput}
                                    >
                                        <FiEdit />
                                    </Button>
                                    <input
                                        type="file"
                                        className="hidden"
                                        id="profileImage"
                                        onChange={handleChangeProfileImage}
                                    />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        icon
                                        size="sm"
                                        className="ml-2"
                                        onClick={() => setNewProfileImage(null)}
                                    >
                                        <BsTrash />
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-stretch border-t border-b border-gray-300">
                        <div className="min-w-[144px] max-w-[144px] w-full text-center text-white font-medium p-3 bg-primary mr-5">
                            작가 설명
                        </div>

                        <div className="w-full p-2">
                            <TextArea
                                variant="outlined"
                                color="gray"
                                rows={4}
                                value={designerInfo.profile?.bio}
                                name="bio"
                                onChange={handleChangeData}
                                disabled={isRetireUser}
                                className="!text-font-input"
                            />
                        </div>
                    </div>
                </div>

                <div className="mb-20">
                    <h3 className="mb-2 text-lg font-medium">정산 정보</h3>

                    <div className="mb-6">
                        <div className="flex items-center border-t border-gray-300">
                            <div className="flex items-center w-1/2">
                                <div className="p-3 mr-5 font-medium text-center text-white w-36 f-center bg-primary">
                                    사업자 형태
                                </div>

                                <div className="w-full max-w-xss">
                                    <Select
                                        className="w-full"
                                        name="business_type"
                                        value={designerInfo?.profile?.business_type}
                                        onChange={handleChangeData}
                                        disabled={isRetireUser}
                                    >
                                        <option value="0">개인 작가</option>
                                        <option value="1">개인 사업자</option>
                                        <option value="2">법인 사업자</option>
                                    </Select>
                                </div>
                            </div>

                            {designerInfo?.profile?.business_type === "0" ? (
                                <div className="flex items-center w-1/2">
                                    <div className="p-3 mr-5 font-medium text-center text-white w-36 f-center bg-primary">
                                        구 분
                                    </div>

                                    <div className="w-full max-w-xss">
                                        <Select
                                            className="w-full"
                                            name="earn_type"
                                            value={designerInfo?.profile?.earn_type}
                                            onChange={handleChangeData}
                                            disabled={isRetireUser}
                                        >
                                            <option value="NULL">선택안함</option>
                                            <option value="BUSINESS">사업소득</option>
                                            <option value="ETC">기타소득</option>
                                        </Select>
                                    </div>
                                </div>
                            ) : (
                                <div className="flex items-center w-1/2">
                                    <div className="p-3 mr-5 font-medium text-center text-white w-36 f-center bg-primary">
                                        상호명
                                    </div>

                                    <div className="max-w-xss">
                                        <Input
                                            variant="outlined"
                                            color="gray"
                                            value={designerInfo.profile?.company_name}
                                            onChange={handleChangeData}
                                            name="company_name"
                                            disabled={isRetireUser}
                                            className="!text-font-input"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="flex items-center border-t border-gray-300">
                            {designerInfo?.profile?.business_type === "0" && (
                                <>
                                    <div className="flex items-center w-1/2">
                                        <div className="p-3 mr-5 font-medium text-center text-white w-36 f-center bg-primary">
                                            성 명
                                        </div>

                                        <div className="w-full max-w-xss">
                                            <Input
                                                variant="outlined"
                                                color="gray"
                                                value={designerInfo.profile?.name}
                                                onChange={handleChangeData}
                                                name="name"
                                                disabled={isRetireUser}
                                                className="!text-font-input"
                                            />
                                        </div>
                                    </div>

                                    <div className="flex items-center w-1/2">
                                        <div className="min-w-[144px] w-36 text-center text-white font-medium f-center p-3 bg-primary mr-5">
                                            주민등록번호
                                        </div>

                                        <div className="flex gap-2">
                                            <div className="max-w-xss">
                                                <Input
                                                    type="text"
                                                    variant="outlined"
                                                    color="gray"
                                                    name="first"
                                                    value={personalNum.first}
                                                    onChange={handleChangePersonalNum}
                                                    disabled={isRetireUser}
                                                    className="!text-font-input"
                                                />
                                            </div>
                                            <span>-</span>
                                            <div className="max-w-xss">
                                                <Input
                                                    type="text"
                                                    variant="outlined"
                                                    color="gray"
                                                    name="second"
                                                    value={personalNum.second}
                                                    onChange={handleChangePersonalNum}
                                                    disabled={isRetireUser}
                                                    className="!text-font-input"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            {designerInfo?.profile?.business_type !== "0" && (
                                <>
                                    <div className="flex items-center w-1/2">
                                        <div className="p-3 mr-5 font-medium text-center text-white w-36 f-center bg-primary">
                                            대표자명
                                        </div>

                                        <div className="w-full max-w-xss">
                                            <Input
                                                variant="outlined"
                                                color="gray"
                                                value={designerInfo.profile?.business_name}
                                                onChange={handleChangeData}
                                                name="business_name"
                                                disabled={isRetireUser}
                                                className="!text-font-input"
                                            />
                                        </div>
                                    </div>

                                    <div className="flex items-center w-1/2">
                                        <div className="min-w-[144px] w-36 text-center text-white font-medium f-center p-3 bg-primary mr-5">
                                            사업자등록번호
                                        </div>

                                        <div className="flex gap-2">
                                            <div className="max-w-xss">
                                                <Input
                                                    type="text"
                                                    variant="outlined"
                                                    color="gray"
                                                    name="company_number"
                                                    value={designerInfo?.profile?.company_number}
                                                    onChange={handleChangeData}
                                                    disabled={isRetireUser}
                                                    className="!text-font-input"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>

                        {designerInfo?.profile?.business_type !== "0" && (
                            <div className="flex items-center w-full border-t bordergray-300">
                                <div className="p-3 mr-5 font-medium text-center text-white w-36 f-center bg-primary">
                                    사업자등록증
                                </div>

                                <div className="flex items-center justify-between">
                                    <p
                                        className="flex items-center gap-8 mr-20 text-sm underline cursor-pointer text-primary"
                                        onClick={() => handleDownloadFile("business")}
                                    >
                                        {certFile !== null ? certFile?.name : designerInfo?.profile?.cert_file_name}
                                    </p>
                                    <div>
                                        <label
                                            htmlFor="certfile"
                                            className="px-2 py-1 text-sm font-semibold text-white rounded-full bg-primary"
                                        >
                                            변경하기
                                        </label>
                                        <input
                                            type="file"
                                            id="certfile"
                                            className="hidden"
                                            onChange={handleChangeCertFile}
                                            disabled={isRetireUser}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="flex items-center border-t border-gray-300">
                            <div className="flex items-center w-1/2">
                                <div className="p-3 mr-5 font-medium text-center text-white w-36 f-center bg-primary">
                                    은행정보
                                </div>

                                <div className="w-full max-w-xss">
                                    <Select
                                        className="w-full"
                                        onChange={handleChangeData}
                                        name="bank_name"
                                        value={designerInfo.profile.bank_name}
                                        disabled={isRetireUser}
                                    >
                                        {bankList.map((bank) => (
                                            <option key={bank.id} value={bank.value}>
                                                {bank.value}
                                            </option>
                                        ))}
                                    </Select>
                                </div>
                            </div>

                            <div className="flex items-center w-1/2">
                                <div className="p-3 mr-5 font-medium text-center text-white w-36 f-center bg-primary">
                                    계좌번호
                                </div>

                                <div className="max-w-xs">
                                    <Input
                                        variant="outlined"
                                        color="gray"
                                        name="bank_num"
                                        value={designerInfo.profile?.bank_num}
                                        onChange={handleChangeData}
                                        disabled={isRetireUser}
                                        className="!text-font-input"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="flex items-center border-t border-b border-gray-300">
                            <div className="flex items-center w-1/2">
                                <div className="p-3 mr-5 font-medium text-center text-white w-36 f-center bg-primary">
                                    예금주
                                </div>

                                <div className="w-full max-w-xss">
                                    <Input
                                        variant="outlined"
                                        color="gray"
                                        value={designerInfo.profile?.bank_holder}
                                        onChange={handleChangeData}
                                        name="bank_holder"
                                        className="!text-font-input"
                                        disabled={isRetireUser}
                                    />
                                </div>
                            </div>

                            <div className="flex items-center w-1/2">
                                <div className="p-3 mr-5 font-medium text-center text-white w-36 f-center bg-primary">
                                    통장사본
                                </div>

                                <div className="flex items-center justify-between">
                                    <p
                                        className="flex items-center gap-8 mr-20 text-sm underline cursor-pointer underline-offset-2 text-primary"
                                        onClick={() => handleDownloadFile("bank")}
                                    >
                                        {bankFile !== null ? bankFile.name : designerInfo?.profile?.bank_file_name}
                                    </p>
                                    <div>
                                        <label
                                            htmlFor="bankfile"
                                            className="px-2 py-1 text-sm font-semibold text-white rounded-full bg-primary"
                                        >
                                            변경하기
                                        </label>
                                        <input
                                            type="file"
                                            id="bankfile"
                                            className="hidden"
                                            onChange={handleChangeBankFile}
                                            disabled={isRetireUser}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="action f-center">
                    <Button variant="contained" color="primary" size="lg" onClick={onSubmitEdit}>
                        저장하기
                    </Button>
                </div>
            </section>

            <section className="mt-10 products">
                <div className="flex items-center mb-3">
                    <h5 className="text-gray">상품개수 : {designerInfo?.products?.length}건</h5>
                    <div className="ml-auto">
                        <Button variant="outlined" color="green" size="sm" className="!rounded-full f-center">
                            <img
                                src={excelIcon}
                                alt="excel"
                                height="19px"
                                width="19px"
                                className="object-contain mr-2"
                            />
                            엑셀로 내려받기
                        </Button>
                    </div>
                </div>

                <div className="relative overflow-x-auto">
                    <table className="w-full text-sm text-left text-black border border-gray-300 whitespace-nowrap">
                        <thead className="text-base bg-gray-100 border-b border-b-gray-300">
                            <tr>
                                <th
                                    scope="col"
                                    className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                                >
                                    등록일
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                                >
                                    대분류
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                                >
                                    소분류
                                </th>
                                <th
                                    scope="col"
                                    className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                                >
                                    상품명
                                </th>

                                <th
                                    scope="col"
                                    className="px-6 py-4 font-medium text-center border-r border-r-gray-300"
                                >
                                    판매가
                                </th>
                            </tr>
                        </thead>
                        <tbody className="font-normal whitespace-nowrap">
                            {designerInfo.products.map((product, i) => (
                                <tr key={i} className="bg-white border-b border-gray-300 last:border-b-0">
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300">
                                        {formatDateSafari(product.created_at) || "-"}
                                    </td>
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300">
                                        {formatCate(product.cate) || "-"}
                                    </td>
                                    <td className="px-6 py-3 text-center border-r border-r-gray-300">
                                        {formatSubcate(product.subcate) || "-"}
                                    </td>
                                    <td className="px-6 py-3 text-center underline border-r border-r-gray-300 text-primary">
                                        <Link
                                            to={`/admin/product/list/${product.id}`}
                                            state={{ brandname: designerInfo.profile.brand_name }}
                                        >
                                            {product.name || "-"}
                                        </Link>
                                    </td>

                                    <td className="px-6 py-3 text-center border-r border-r-gray-300">
                                        {formatPrice(product.price_tiers_id) || "-"}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
    );
};

export default AuthorInformation;
